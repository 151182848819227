import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import './HomeComponent.css';

const TimerSegment = ({ value, label }) => (
  <div className="timer-segment">
    <div className="timer-value">{value}</div>
    <div className="timer-label">{label}</div>
  </div>
);

const Button = ({ className, children, onClick }) => (
  <button className={className} onClick={onClick}>
    {children}
  </button>
);

const REGISTRATION_ADDRESSES = {
  1: "0xb4B4EFdC62304613878F76FbA6F6A5b2C7D08487",
  56: "0x575B4356e98Ac6DE554bfAFAD19Ad0B101775ee1",
  8453: "0xb4B4EFdC62304613878F76FbA6F6A5b2C7D08487",
}

const HomeComponent = ({ setAction, web3, account, networkId }) => {
  const [timeLeft, setTimeLeft] = useState({days: 0, hours: 0, minutes: 0, seconds: 0});
  const [registrationStatus, setRegistrationStatus] = useState('');

  // Set your target date here
  const TARGET_DATE = new Date('2024-10-22T09:00:00').getTime();

  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date().getTime();
      const difference = TARGET_DATE - now;
      
      if (difference > 0) {
        return {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
          minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
          seconds: Math.floor((difference % (1000 * 60)) / 1000)
        };
      }
      return {days: 0, hours: 0, minutes: 0, seconds: 0};
    };

    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleRegister = async () => {
    if (!web3 || !account) {
      setRegistrationStatus('Please connect your wallet first.');
      return;
    }

    if (!REGISTRATION_ADDRESSES[networkId]) {
      setRegistrationStatus('Unsupported network. Please switch to a supported network.');
      return;
    }

    try {
      const contractABI = [
        {
          "inputs": [],
          "name": "register",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        },
        {
          "inputs": [],
          "name": "amIRegistered",
          "outputs": [
            {
              "internalType": "bool",
              "name": "",
              "type": "bool"
            }
          ],
          "stateMutability": "view",
          "type": "function"
        },
        // ... (other functions from the ABI)
      ];

      const contract = new web3.eth.Contract(contractABI, REGISTRATION_ADDRESSES[networkId]);

      if(networkId.toString() === "1") {
        const maxPriorityFeePerGas = web3.utils.toWei('0.1', 'gwei');
        await contract.methods.register().send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
      } else if(networkId.toString() === "8453") {
        const maxPriorityFeePerGas = web3.utils.toWei('0.0001', 'gwei');
        await contract.methods.register().send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
      } else {
        await contract.methods.register().send({ from: account });
      }
      
      setRegistrationStatus('Registration successful!');
    } catch (error) {
      console.error('Registration error:', error);
      setRegistrationStatus('Registration failed. Please try again.');
    }
  };

  const handleButtonClick = (buttonText) => {
    switch (buttonText) {
      case 'Buzz Board':
        setAction('messageboard');
        break;
      case 'Messenger':
        setAction('messenger');
        break;
      // Add more cases if needed
      default:
        break;
    }
  };

  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to Block Buzzz</h1>
      
      <div className="timer-container">
        <TimerSegment value={timeLeft.days} label="Days" />
        <TimerSegment value={timeLeft.hours} label="Hours" />
        <TimerSegment value={timeLeft.minutes} label="Minutes" />
        <TimerSegment value={timeLeft.seconds} label="Seconds" />
      </div>
      
      <p className="home-text">UNTIL THE LAUNCH OF BUZZ COIN</p>
      <p className="home-text" style={{marginBottom: '0rem'}}></p>
      
      <Button className="register-button" onClick={handleRegister}>
        Register for Airdrop
      </Button>
      
      {registrationStatus && <p className="home-text">{registrationStatus}</p>}
      
      <div>
        <p className="home-text" style={{marginBottom: '0rem'}}></p>
        <div className="button-container">
          {['Buzz Board', 'Messenger', 'Buzz Game'].map((text, index) => (
            <Button
              key={index}
              className="pulse-button pulse"
              onClick={() => handleButtonClick(text)}
            >
              {text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeComponent;