import React from 'react';
import './FooterComponent.css';

function FooterComponent() {
  return (
    <footer className="footer">
      <div className="content-container">
        <div className="disclaimer">
          <p>
            Disclaimer: Buzz Block is currently in alpha state. Use at your own risk. We are not financial advisors, and 
            all information provided is for educational purposes only. Cryptocurrency investments are volatile and high-risk. 
            Always do your own research before making any investment decisions.
          </p>
          <p>
            © 2024 Buzz Block. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default FooterComponent;