// compression.js

const MAX_DICT_SIZE = 4096; // Limit dictionary size

function bitPack(input) {
    const output = [];
    let bitBuffer = 0;
    let bitCount = 0;
    for (const code of input) {
        bitBuffer = (bitBuffer << 12) | code;
        bitCount += 12;
        while (bitCount >= 8) {
            bitCount -= 8;
            output.push((bitBuffer >> bitCount) & 0xFF);
        }
    }
    if (bitCount > 0) {
        output.push((bitBuffer << (8 - bitCount)) & 0xFF);
    }
    return new Uint8Array(output);
}

function bitUnpack(input) {
    const output = [];
    let bitBuffer = 0;
    let bitCount = 0;
    for (const byte of input) {
        bitBuffer = (bitBuffer << 8) | byte;
        bitCount += 8;
        while (bitCount >= 12) {
            bitCount -= 12;
            output.push((bitBuffer >> bitCount) & 0xFFF);
        }
    }
    return output;
}

export function compress(uint8Array) {
    const dictionary = new Map();
    const result = [];
    let dictSize = 256;
    for (let i = 0; i < 256; i++) {
        dictionary.set(String.fromCharCode(i), i);
    }
    let w = String.fromCharCode(uint8Array[0]);
    for (let i = 1; i < uint8Array.length; i++) {
        const c = String.fromCharCode(uint8Array[i]);
        const wc = w + c;
        if (dictionary.has(wc)) {
            w = wc;
        } else {
            result.push(dictionary.get(w));
            if (dictSize < MAX_DICT_SIZE) {
                dictionary.set(wc, dictSize++);
            }
            w = c;
        }
    }
    result.push(dictionary.get(w));
    return bitPack(result);
}

export function decompress(compressedArray) {
    const inputCodes = bitUnpack(compressedArray);
    const dictionary = new Map();
    let dictSize = 256;
    for (let i = 0; i < 256; i++) {
        dictionary.set(i, String.fromCharCode(i));
    }
    let oldCode = inputCodes[0];
    let w = dictionary.get(oldCode);
    let result = w;
    for (let i = 1; i < inputCodes.length; i++) {
        let code = inputCodes[i];
        let entry;
        if (dictionary.has(code)) {
            entry = dictionary.get(code);
        } else if (code === dictSize) {
            entry = w + w[0];
        } else {
            throw new Error("Decompression error");
        }
        result += entry;
        if(result.length > 300000) {
            throw new Error("Decompression error: Too long");
        }
        if (dictSize < MAX_DICT_SIZE) {
            dictionary.set(dictSize++, w + entry[0]);
        }
        w = entry;
        oldCode = code;
    }
    const output = new Uint8Array(result.length);
    for (let i = 0; i < result.length; i++) {
        output[i] = result.charCodeAt(i);
    }
    return output;
}