import React, { useState, useEffect, useRef } from 'react';
import logo from './PageLogo.png';
import './HeaderComponent.css';

const WalletDisplay = ({ account, networkName, web3, setAccount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const dropdownRef = useRef(null);

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      if (web3) {
        const fetchedAccounts = await web3.eth.getAccounts();
        setAccounts(fetchedAccounts);
      }
    };
    fetchAccounts();
  }, [web3]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAccountSwitch = (newAccount) => {
    setAccount(newAccount);
    setIsOpen(false);
  };

  return (
    <div className="wallet-display" ref={dropdownRef}>
      <button className="wallet-button" onClick={() => setIsOpen(!isOpen)}>
        {shortenAddress(account)}
      </button>
      {isOpen && (
        <div className="wallet-dropdown">
          <p>{networkName}</p>
          {accounts.map((acc, index) => (
            <button 
              key={index} 
              className={`account-button ${acc === account ? 'active' : ''}`}
              onClick={() => handleAccountSwitch(acc)}
            >
              {shortenAddress(acc)}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

function HeaderComponent({ setAction, connectWallet, account, networkId, web3, setAccount, setTheme, currentTheme }) {
  const [networkName, setNetworkName] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);

  const handleLogoClick = () => setAction('home');
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  
  const handleActionClick = (action) => {
    setAction(action);
    setShowDropdown(false);
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
  };

  useEffect(() => {
    const fetchNetworkName = () => {
      switch (networkId) {
        case 1:
          setNetworkName('Ethereum');
          break;
        case 56:
          setNetworkName('BSC');
          break;
        default:
          setNetworkName('Unknown Network: ' + networkId);
      }
    };
    fetchNetworkName();
  }, [networkId]);

  return (
    <header className="header">
      <nav className="nav">
        <img src={logo} alt="Logo" className="logo" onClick={handleLogoClick} />
        <div className="hamburger" onClick={toggleDropdown}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <ul className={`menu ${showDropdown ? 'show' : ''}`}>
          <li><h1 onClick={() => handleActionClick('messageboard')}>Buzz Board</h1></li>
          <li><h1 onClick={() => handleActionClick('messenger')}>Messenger</h1></li>
          <li><h1><a href="https://blockbuzzz.gitbook.io/block-buzzz-docs/getting-started/quickstart">Docs</a></h1></li>
        </ul>
      </nav>
      <div className='rightHeader'>
        <div className="theme-buttons">
          <button 
            onClick={() => handleThemeChange('light')} 
            className={`theme-button ${currentTheme === 'light' ? 'active' : ''}`}
            aria-label="Switch to light theme"
          >
            ☀️
          </button>
          <button 
            onClick={() => handleThemeChange('dark')} 
            className={`theme-button ${currentTheme === 'dark' ? 'active' : ''}`}
            aria-label="Switch to dark theme"
          >
            🌙
          </button>
        </div>
        <div className="wallet-container">
          {account ? (
            <WalletDisplay
              account={account}
              networkName={networkName}
              web3={web3}
              setAccount={setAccount}
            />
          ) : (
            <button className="connect-button" onClick={connectWallet}>Connect Wallet</button>
          )}
        </div>
      </div>
    </header>
  );
}

export default HeaderComponent;
