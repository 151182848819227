import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import './theme.css';
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import MessengerComponent from './components/MessengerComponent';
import HomeComponent from './components/HomeComponent';
import MessageBoardComponent from './components/MessageBoardComponent';
import Web3 from 'web3';

const Actions = {
  HOME: 'home',
  MESSAGEBOARD: 'messageboard',
  MESSENGER: 'messenger',
};

function App() {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState('');
  const [networkId, setNetworkId] = useState(null);
  const [action, setAction] = useState(() => {
    const path = window.location.pathname.substring(1);
    return Object.values(Actions).includes(path) ? path : Actions.HOME;
  });
  const [theme, setTheme] = useState('dark');

  const handleAccountsChanged = useCallback((accounts) => {
    if (accounts.length === 0) {
      setAccount('');
    } else if (accounts[0] !== account) {
      setAccount(accounts[0]);
    }
  }, [account]);

  const handleChainChanged = () => {
    window.location.reload();
  };

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
    document.body.className = newTheme;
    localStorage.setItem('theme', newTheme);
  };

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      setTheme(savedTheme);
      document.body.className = savedTheme;
    }
  }, []);

  const initWeb3 = useCallback(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);
    } else {
      console.log('Please install MetaMask!');
    }
  }, [handleAccountsChanged]);

  useEffect(() => {
    initWeb3();
    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [initWeb3, handleAccountsChanged]);

  const connectWallet = useCallback(async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);
        const networkId = await web3.eth.net.getId();
        setNetworkId(networkId);
      } catch (error) {
        console.error('User denied account access');
      }
    } else {
      console.log('Please install MetaMask!');
    }
  }, [web3]);

  const setActionWithHistory = useCallback((newAction) => {
    const newPath = newAction === Actions.HOME ? '/' : `/${newAction}`;
    window.history.pushState({ action: newAction }, '', newPath);
    setAction(newAction);
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.state && event.state.action) {
        setAction(event.state.action);
      } else {
        setAction(Actions.HOME);
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const renderActionComponent = useCallback(() => {
    switch (action) {
      case Actions.HOME:
        return <HomeComponent 
        setAction={setActionWithHistory} 
        web3={web3} 
        account={account} 
        networkId={networkId}
      />;
      case Actions.MESSAGEBOARD:
        return <MessageBoardComponent web3={web3} account={account} networkId={networkId} />;
      case Actions.MESSENGER:
        return <MessengerComponent web3={web3} account={account} networkId={networkId}/>;
      default:
        return <HomeComponent 
        setAction={setActionWithHistory} 
        web3={web3} 
        account={account} 
        networkId={networkId}
      />;
    }
  }, [action, web3, account, networkId, setActionWithHistory]);

  return (
    <div className={`App ${theme}`}>
      <HeaderComponent
        setAction={setActionWithHistory}
        account={account}
        connectWallet={connectWallet}
        networkId={networkId}
        web3={web3}
        setAccount={setAccount}
        setTheme={toggleTheme}
        currentTheme={theme}
      />
      <main className="main-content">
        {renderActionComponent()}
      </main>
      <FooterComponent />
    </div>
  );
}

export default App;