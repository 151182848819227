import React, { useState, useEffect, useCallback, useRef } from 'react';
import Web3 from 'web3';
import styled from 'styled-components';
import { create } from 'ipfs-http-client';
import { X, Copy, Image as ChevronDown, RefreshCw, MessageSquare, Smile, Heart   } from 'lucide-react';
import { compress, decompress } from './compression';
import { formatDate, getRelativeTimeString } from './dateUtils';
import { Buffer } from 'buffer';
import './MessageBoardComponent.css';

// Import the ABI
import { messageFacetABI } from './messageFacetABI';
import { ZeroAddress } from 'ethers';


const MAX_TEXT_LENGTH = 1000;
const MAX_COMMENT_LENGTH = 400;
const LOCAL_STORAGE_KEY = 'messageBoard_messages';
const LOCAL_STORAGE_KEY_LOADED_COUNTS = 'messageBoard_loadedCounts';
const MESSAGES_PER_FETCH = 7;
const COMMENTS_PER_FETCH = 7;

const ipfs = create({ url: 'https://ipfs.infura.io:5001/api/v0' });

const Card = styled.div` 
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 0rem;
  display: flex;
  flex-direction: column;
  color: white;
  width: 100%;
  height: 300px;
  position: relative;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 150px;
  object-fit: cover;
  margin-top: 0.5rem;
`;

const SendButton = styled.button`
  display: block;
  margin: 2rem auto;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  z-index: 2;
`;

const Input = styled.input`
  width: 95%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: white;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 100px;
  max-height: 250px;
  padding: 0.5rem;
  margin-bottom: 0rem;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  resize: vertical;
  background-color: #2a2a2a;
  color: white;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  ${props => props.$primary && `
    background-color: #4CAF50;
    color: white;
  `}
  
  ${props => props.$secondary && `
    background-color: #f44336;
    color: white;
  `}
`;

const Slider = styled.input.attrs({ type: 'range' })`
  width: 95%;
  margin: 0rem auto;
  display: block;
`;


const TimeStamp = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: #888;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 1rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: #2196F3;
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`;

const UploadBlock = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(28, 28, 28, 0.95);
  color: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const UploadContent = styled.div`
  display: flex;
  gap: 2rem;
  height: calc(100% - 60px);
`;

const InputSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PreviewSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const PreviewText = styled.p`
  white-space: pre-line;
  word-break: break-word;
  margin: 0;
`;

const SectionTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 0.8rem;
`;

const InputArea = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const PreviewCard = styled(Card)`
  flex-grow: 1;
  overflow-y: hidden;
  max-height: 250px;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0rem;
`;

const SwitchLabel = styled.label`
  margin-top: -15px;
  margin-left: 10px;
  color: #4a90e2;
`;

const ContinueReadingButton = styled.button`
  background: none;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const ExpandedCardOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;
const ExpandedCard = styled.div`
  background-color: #2a2a2a;
  border-radius: 8px;
  white-space: pre-line;
  padding: 1rem;
  color: white;
  width: 80%;
  max-width: 600px;
  min-height: 200px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const ActionButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const CommentButton = styled.button`
  background: none;
  border: none;
  color: #4CAF50;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const CommentCard = styled.div`
  background-color: #333;
  border-radius: 8px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

const CommentInput = styled.textarea`
  width: 95%;
  padding: 0.5rem;
  margin-top: 1rem;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: white;
  resize: vertical;
`;

const CommentSubmitButton = styled(Button)`
  margin-top: 0.5rem;
  width: 30%;
`;

// Update the CommentSection styled component
const CommentSection = styled.div`
  margin-top: 1rem;
  border-top: 1px solid #3a3a3a;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  max-height: 500px; // Set a maximum height
`;

// Add a new styled component for the scrollable comment list
const CommentList = styled.div`
  overflow-y: auto;
  max-height: 300px; // Adjust this value as needed
  margin-bottom: 1rem;
  padding-right: 0.5rem; // Add some padding for the scrollbar

  /* Customize scrollbar for webkit browsers */
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #2a2a2a;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }

  /* Customize scrollbar for Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #2a2a2a;
`;

const HeartButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: ${props => props.$active ? '#ff4136' : '#888'};
  transition: color 0.3s ease;
  z-index: 1;

  &:hover {
    color: #ff4136;
  }
`;


const UpvoteCount = styled.span`
  margin-left: 5px;
  font-size: 0.8rem;
`;

const ExpandedHeartButton = styled(HeartButton)`
  top: 0.5rem;
  right: 2.5rem; // Move it to the left of the close button
`;

const EmojiPickerContainer = styled.div`
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 0.25rem;
  z-index: 1000;
`;

const EmojiButton = styled.button`
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.25rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3a3a3a;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ChainIndicatorWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
`;

const ChainIndicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.color};
  cursor: pointer;
`;

const ChainTooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 1000;

  ${ChainIndicatorWrapper}:hover & {
    opacity: 1;
    visibility: visible;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #333 transparent;
  }
`;
const LoadAllChainsButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
`;

const SenderInfoContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const CopyIcon = styled(Copy)`
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  color: #4CAF50;
`;

const SenderInfo = styled.p`
  margin: 0;
  cursor: pointer;

  &:hover + ${CopyIcon} {
    opacity: 1;
  }
`;

const GridItem = styled.div`
  text-align: right;
  color: ${props => props.color || '#ffffff'};
  white-space: nowrap;
`;

const TotalColumn = styled(GridItem)`
  font-weight: bold;
  padding-left: 1rem;
  border-left: 1px solid #444;
`;

const LoadedCount = styled.span`
  color: #888;
  margin-left: 0.25rem;
`;

const WarningNote = styled.p`
  color: #ff9800;
  font-size: 0.9rem;
  margin-top: 1rem;
  padding: 0.5rem;
  border: 1px solid #ff9800;
  border-radius: 4px;
  background-color: rgba(255, 152, 0, 0.1);
`;


const NETWORKS = {
  97: {
    rpcUrl: 'https://bsc-testnet-dataseed.bnbchain.org',
    contractAddress: '0x14B33232fcd0B7C18Dd1eD50dA46ef3Ab0ADCFd3',
    color: '#cba13e',
    name: "BSC Test"
  },
  56: {
    rpcUrl: 'https://bsc-dataseed.bnbchain.org',
    contractAddress: '0x0937d22e6caB92cd3dA981f9a85fCF2F4A907aed',
    color: '#F0B90B',
    name: "BSC Main"
  },
  8453: {
    rpcUrl: 'https://base.llamarpc.com',
    contractAddress: '0x0937d22e6caB92cd3dA981f9a85fCF2F4A907aed',
    color: '#3eafcb',
    name: "BASE"
  },
  1: {
    rpcUrl: 'https://eth.llamarpc.com',
    contractAddress: '0xa1fF5Cca0FDC74dC0d3a50a18c2C3dc4E9Fc8Ed4',
    color: '#497493',
    name: "ETH"
  }
};

const CustomChainDropdown = ({ networks, selectedChains, onChange, messageCounts, loadedMessageCounts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleChain = (chain) => {
    onChange(chain);
  };

  const isAllSelected = selectedChains.length === Object.keys(networks).length;

  const getHeaderText = () => {
    if (selectedChains.length === Object.keys(networks).length) {
      return "All Chains";
    } else if (selectedChains.length > 1) {
      return `${selectedChains.length} Chains Selected`;
    } else if (selectedChains.length === 1) {
      return networks[selectedChains[0]].name;
    } else {
      return "Select Chains";
    }
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        <span>{getHeaderText()}</span>
        <span className="dropdown-arrow">&#9662;</span>
      </div>
      {isOpen && (
        <div className="dropdown-options">
          <label className="dropdown-option">
            <input
              type="checkbox"
              checked={isAllSelected}
              onChange={() => toggleChain('all')}
            />
            <span className="checkmark"></span>
            All Chains
          </label>
          {Object.entries(networks).map(([chain, { name, color }]) => (
            <label key={chain} className="dropdown-option" style={{ color }}>
              <input
                type="checkbox"
                checked={selectedChains.includes(chain)}
                onChange={() => toggleChain(chain)}
              />
              <span className="checkmark"></span>
              {name} ({messageCounts[chain] || 0}/{loadedMessageCounts[chain]?.total || 0})
            </label>
          ))}
        </div>
      )}
    </div>
  );
};



const COMPRESSION_PREFIX = new Uint8Array([0, 0, 0, 0, 0, 0, 0, 0]);

const MessageBoardComponent = ({ web3, account, networkId }) => {
  const [messages, setMessages] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [text, setText] = useState('');
  const [image, setImage] = useState(null);
  const [compressedImage, setCompressedImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [compressionLevel, setCompressionLevel] = useState(70);
  const [useIPFS, setUseIPFS] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [filterType, setFilterType] = useState('all');
  const [filterSender, setFilterSender] = useState('');
  const [expandedMessage, setExpandedMessage] = useState(null);
  const [overflowingCards, setOverflowingCards] = useState({});
  const [hasMoreOlderMessages, setHasMoreOlderMessages] = useState(true);
  const [hasMoreOlderComments, setHasMoreOlderComments] = useState({});
  const [commentText, setCommentText] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [messageCounts, setMessageCounts] = useState({});
  const [selectedChains, setSelectedChains] = useState([]);
  const [loadedMessageCounts, setLoadedMessageCounts] = useState({});
  const [contracts, setContracts] = useState({});
  const cardRefs = useRef({});

  const emojis = ['😀', '😂', '😍', '🤔', '😎', '👍', '❤️', '🎉', '🔥', '👀', '💯', '🙌', '👏', '🤝', '🍕', '🌈'];

  BigInt.prototype.toJSON = function() {return this.toString()}

  useEffect(() => {
    const initWeb3Instances = async () => {
      const contractInstances = {};

      for (const [network, { rpcUrl, contractAddress }] of Object.entries(NETWORKS)) {
        const web32 = new Web3(new Web3.providers.HttpProvider(rpcUrl));
        contractInstances[network] = new web32.eth.Contract(messageFacetABI, contractAddress);
      }
      setContracts(contractInstances);

      try {
        const storedCounts = localStorage.getItem(LOCAL_STORAGE_KEY_LOADED_COUNTS);
        if (storedCounts) {
          setLoadedMessageCounts(JSON.parse(storedCounts));
        }
        const storedMessages = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (storedMessages) {
          setMessages(JSON.parse(storedMessages));
        } else {
          fetchMessages("initial", Object.keys(NETWORKS), contractInstances);
        }
      } catch (error) {
        console.error("Error in setting messages", error);
      }
    };

    initWeb3Instances();
  }, []);

  const handleClear = () => {
    if (window.confirm('Are you sure you want to clear all locally stored messages? This will reset the message board.')) {
      localStorage.removeItem(LOCAL_STORAGE_KEY);
      localStorage.removeItem(LOCAL_STORAGE_KEY_LOADED_COUNTS);
      setMessages([]);
      setLoadedMessageCounts({});
      setHasMoreOlderMessages(true);
      setSelectedChains([])
    }
  };

  useEffect(() => {
    if (expandedMessage) {
      const updatedMessage = messages.find(m => m.id === expandedMessage.id);
      if (updatedMessage) {
        setExpandedMessage(updatedMessage);
      }
    }
  }, [messages, expandedMessage]);

  const fetchMessageCounts = useCallback(async () => {
    const counts = {};
    let total = 0;
    for (const [network] of Object.entries(NETWORKS)) {
      const contract = contracts[network];
      if (contract) {
        const count = await contract.methods.totalMessages().call();
        counts[network] = Number(count);
        total += Number(count);
      }
    }
    counts.TOTAL = total;
    setMessageCounts(counts);
  }, [contracts]);

  useEffect(() => {
    if (Object.keys(contracts).length > 0) {
      fetchMessageCounts();
    }
  }, [contracts, fetchMessageCounts]);

  const saveLoadedCounts = (counts) => {
    localStorage.setItem(LOCAL_STORAGE_KEY_LOADED_COUNTS, JSON.stringify(counts));
  };

  const updateLoadedMessageCounts = useCallback((updates) => {
    setLoadedMessageCounts(prev => {
      const updated = { ...prev };
      let totalDelta = 0;
  
      updates.forEach(({ network, from, to }) => {
        if (updated[network]) {
          const oldFrom = updated[network].from;
          const oldTo = updated[network].to;
          
          updated[network].from = Math.min(from, oldFrom);
          updated[network].to = Math.max(to, oldTo);
          updated[network].total = updated[network].to - updated[network].from;
          
          totalDelta += (updated[network].to - oldTo) - (oldFrom - updated[network].from);
        } else {
          updated[network] = {
            total: to - from,
            from: from,
            to: to
          };
          totalDelta += to - from;
        }
      });
  
      if (updated.TOTAL) {
        updated.TOTAL += totalDelta;
      } else {
        updated.TOTAL = totalDelta;
      }
  
      saveLoadedCounts(updated);
      return updated;
    });
  }, []);
  
  const decodeMessage = useCallback((message) => {
    try {
      const hexData = message.startsWith('0x') ? message.slice(2) : message;
      const uint8Array = hexToArrayBuffer(hexData);
      
      let decodedData;
      const decoder = new TextDecoder();
      if (uint8Array.slice(0, 8).every((byte, index) => byte === COMPRESSION_PREFIX[index])) {
        decodedData = decompress(uint8Array.slice(8));
        decodedData = JSON.parse(decoder.decode(decodedData));
      } else {
        decodedData = JSON.parse(decoder.decode(uint8Array));
      }

      return {
        text: decodedData.text || '',
        image: decodedData.image || null,
        type: decodedData.image ? (decodedData.image.startsWith('Qm') ? 'ipfs' : 'direct') : 'text'
      };
    } catch (error) {
      console.error('Error decoding message:', error);
      return {
        text: 'Error: Could not decode message',
        image: null,
        type: 'error'
      };
    }
  }, []);  // Empty dependency array as it doesn't depend on any component state or props

  const fetchMessages = useCallback(async (fetchType = 'initial', networks=[networkId], contracts2=contracts) => {
    if (loading || !networks) return;

    setLoading(true);
    
    try {
      let updatedMessages = [...messages];
      let updates = []
      for(let i = 0; i < networks.length; i++) {
        const network = networks[i];
        if(!contracts2[network]) continue;
        let newMessages = [];
        let totalMessages = await contracts2[network].methods.totalMessages().call();
        totalMessages = Number(totalMessages);
        if(totalMessages <= 0) {
          continue;
        }

        if(totalMessages > messageCounts[network]) {
          const newCounts = {...messageCounts};
          newCounts.TOTAL = newCounts.TOTAL + totalMessages - messageCounts[network];
          newCounts[network] = totalMessages;
          setMessageCounts(newCounts);
        }
        let from, to;


        if (fetchType === 'initial' || !loadedMessageCounts[network]) {
          if (loadedMessageCounts[network]) {
            continue;
          }
          from = Math.max(totalMessages - MESSAGES_PER_FETCH, 0);
          to = totalMessages;
        } else if (fetchType === 'new') {
          const lastTo = loadedMessageCounts[network].to;
          if (!loadedMessageCounts[network] || lastTo >= totalMessages) {
            continue;
          }
          from = lastTo;
          to = Math.min(lastTo + MESSAGES_PER_FETCH, totalMessages);
        } else if (fetchType === 'older') {
          const lastFrom = loadedMessageCounts[network].from;
          if (lastFrom <= 0) {
            continue;
          }
          from = Math.max(lastFrom - MESSAGES_PER_FETCH, 0);
          to = lastFrom; 
        }

        const fromAccount = account || ZeroAddress;
        const result = await contracts2[network].methods.getMessages(from, to).call({from: fromAccount});

        newMessages = result.newMessages.map((message, index) => ({
          identifier: from + index,
          id: network.toString() + (from + index).toString(),
          content: decodeMessage(message),
          sender: result.messageOwners[index],
          timestamp: new Date(Number(result.messageTimes[index]) * 1000),
          rawTime: Number(result.messageTimes[index]),
          upvotes: Number(result.upvotes[index]),
          userUpvoted: result.upvoted[index],
          commentCount: Number(result.messageCommentLength[index]),
          network: network,
          comments: null // Initialize comments as null
        }));

        let lastInsert = 0;
        for(let i = newMessages.length - 1; i >= 0; i--) {
          let currentTime = newMessages[i].rawTime;
          for(let j = lastInsert; j < updatedMessages.length; j++) {
            if(currentTime > updatedMessages[j].rawTime) {
              break;
            }
            lastInsert = j + 1;
          }
          updatedMessages.splice(lastInsert, 0, newMessages[i]);
        }
        updates.push({"network": network, "from":from,"to":to})
        setMessages(updatedMessages);
      }
      updateLoadedMessageCounts(updates);
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedMessages));
      setLoading(false);
    } catch (error) {
      setError('Error fetching messages: ' + error.message);
      setLoading(false);
    }
  }, [contracts, account, messages, loadedMessageCounts, decodeMessage, loading, messageCounts, networkId, updateLoadedMessageCounts]);

  const fetchComments = useCallback(async (messageId, fetchType = 'initial') => {
    if (!web3) return;

    setLoading(true);
    try {
      const message = messages.find(m => m.id === messageId);
      if (!message) throw new Error('Message not found');

      let from, to;
      const totalComments = message.commentCount;
      const messageNetwork = message.network;
      const contract = contracts[messageNetwork];

      if (fetchType === 'initial') {
        to = totalComments;
        from = Math.max(0, to - COMMENTS_PER_FETCH);
      } else if (fetchType === 'older') {
        to = (message.comments && message.comments.length > 0 ? message.comments[0].id : totalComments);
        from = Math.max(0, to - COMMENTS_PER_FETCH);
        if(from === to) {setLoading(false); return;}
      } else if (fetchType === 'newer') {
        const result2 = await contract.methods.getMessages(message.identifier, messageId + 1).call();
        const newCommentCount =  Number(result2.messageCommentLength[0]);
        from = (message.comments && message.comments.length > 0 ? message.comments[message.comments.length - 1].id + 1 : 0);
        to = newCommentCount;

        if(newCommentCount.toString() === totalComments.toString()) {setLoading(false); return;}

        setMessages(prevMessages => prevMessages.map(m => 
          m.id === messageId ? { ...m, commentCount: to } : m
        ));
      }

      const result = await contract.methods.getCommentFromMessage(message.identifier, from, to).call();

      const fetchedComments = result.newMessages.map((comment, index) => ({
        id: from + index,
        content: decodeMessage(comment),
        sender: result.messageOwners[index],
        timestamp: new Date(Number(result.messageTimes[index]) * 1000)
      }));

      setMessages(prevMessages => prevMessages.map(m => {
        if (m.id === messageId) {
          let updatedComments;
          if (fetchType === 'initial' || fetchType === 'newer') {
            updatedComments = [...(m.comments || []), ...fetchedComments];
          } else {
            updatedComments = [...fetchedComments, ...(m.comments || [])];
          }
          return { ...m, comments: updatedComments };
        }
        return m;
      }));

      setHasMoreOlderComments(prev => ({
        ...prev,
        [messageId]: from > 0
      }));

      // Update local storage
      const updatedMessages = messages.map(m => {
        if (m.id === messageId) {
          let updatedComments;
          if (fetchType === 'initial' || fetchType === 'newer') {
            updatedComments = [...(m.comments || []), ...fetchedComments];
          } else {
            updatedComments = [...fetchedComments, ...(m.comments || [])];
          }
          return { ...m, comments: updatedComments };
        }
        return m;
      });
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedMessages));
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
    setLoading(false);
  }, [messages, contracts, web3, decodeMessage]);

  const handleCommentSubmit = async (messageId) => {
    if (!commentText.trim()) return;

    if(!web3) return;
    try {
      const message = messages.find(m => m.id === messageId);
      const messageNetwork = message.network;
      if(messageNetwork !== networkId.toString()) {
        alert("Please switch to the right network to submit the comment");
        return;
      }
      const contract = new web3.eth.Contract(messageFacetABI, NETWORKS[Number(messageNetwork)].contractAddress);

      setLoading(true);
      const data = web3.utils.utf8ToHex(JSON.stringify({ text: commentText }));

      if(networkId.toString() === "1") {
        const maxPriorityFeePerGas = web3.utils.toWei('0.1', 'gwei');
        await contract.methods.commentMessage(data, message.identifier).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
      } else if(networkId.toString() === "8453") {
        const maxPriorityFeePerGas = web3.utils.toWei('0.0001', 'gwei');
        await contract.methods.commentMessage(data, message.identifier).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
      } else {
        await contract.methods.commentMessage(data, message.identifier).send({ from: account });
      }

      setCommentText('');
      
      // Fetch new comments after submitting
      await fetchComments(messageId, 'newer');
    } catch (error) {
      console.log(error);
      setError('Error sending comment: ' + error.message);
    }
    setLoading(false);
  };

  const handleToggleUpvote = async (messageId, currentUpvoteState) => {
    if (!web3 || !account) return;
    

    try {
      const curMessage = messages.find(m => m.id === messageId);
      const messageNetwork = curMessage.network;
      if(messageNetwork !== networkId.toString()) {
        alert("Please switch to the right network to submit the comment");
        return;
      }

      const contract = new web3.eth.Contract(messageFacetABI, NETWORKS[Number(messageNetwork)].contractAddress);
      await contract.methods.toggleUpvote(curMessage.identifier, !currentUpvoteState).send({ from: account });
    
      const updatedMessages = messages.map(message => 
        message.id === messageId 
          ? { 
              ...message, 
              upvotes: currentUpvoteState ? message.upvotes - 1 : message.upvotes + 1,
              userUpvoted: !currentUpvoteState
            }
          : message
      );

      // Update the messages state
      setMessages(updatedMessages);

      // Update local storage
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(updatedMessages));

      // If the upvoted message is the expanded message, update it too
      if (expandedMessage && expandedMessage.id === messageId) {
        const updatedExpandedMessage = {
          ...expandedMessage,
          upvotes: currentUpvoteState ? expandedMessage.upvotes - 1 : expandedMessage.upvotes + 1,
          userUpvoted: !currentUpvoteState
        };
        setExpandedMessage(updatedExpandedMessage);
      }

    } catch (error) {
      console.error('Error toggling upvote:', error);
      setError('Error toggling upvote: ' + error.message);
    }
  };

  const handleFetchOlderComments = (messageId) => {
    fetchComments(messageId, 'older');
  };

  const handleSyncComments = (messageId) => {
    fetchComments(messageId, 'newer');
  };


  const handleFetchNewMessages = () => {
    const toBeLoaded = selectedChains;
    if(toBeLoaded.length) {
      fetchMessages('new', toBeLoaded);
    } else {
      fetchMessages('new');
    }
  };

  const handleFetchOlderMessages = () => {
    const toBeLoaded = selectedChains;
    if(toBeLoaded.length) {
      fetchMessages('older', toBeLoaded);
    } else {
      fetchMessages('older');
    }
  };

  useEffect(() => {
    const checkOverflow = () => {
      const newOverflowingCards = {};
      Object.entries(cardRefs.current).forEach(([index, ref]) => {
        if (ref) {
          newOverflowingCards[index] = ref.scrollHeight > ref.clientHeight;
        }
      });
      setOverflowingCards(newOverflowingCards);
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [messages]);

  const handleTextChange = (e) => {
    setText(e.target.value.slice(0, MAX_TEXT_LENGTH));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setImage(file);
      compressImage(file, compressionLevel);
    }
  };

  const compressImage = (file, quality) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        canvas.toBlob(
          (blob) => {
            setCompressedImage(blob);
            setPreviewImage(URL.createObjectURL(blob));
          },
          'image/jpeg',
          quality / 100
        );
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleCompressionChange = (e) => {
    const value = parseInt(e.target.value);
    setCompressionLevel(value);
    if (image) {
      compressImage(image, 100-value);
    }
  };

  const handleIPFSToggle = (e) => {
    return;
    setUseIPFS(e.target.checked);
  };

  const arrayBufferToHex = (buffer) => {
    return [...new Uint8Array(buffer)]
      .map(x => x.toString(16).padStart(2, '0'))
      .join('');
  };

  const hexToArrayBuffer = (hex) => {
    const pairs = hex.match(/[\dA-F]{2}/gi);
    return new Uint8Array(pairs.map(s => parseInt(s, 16)));
  };

  const prepareMessageData = (data) => {
    const stringData = JSON.stringify(data);
    const encoder = new TextEncoder();
    const uncompressedArray = encoder.encode(stringData);
    const compressedArray = compress(uncompressedArray);

    if (compressedArray.length + COMPRESSION_PREFIX.length < uncompressedArray.length) {
      const result = new Uint8Array(COMPRESSION_PREFIX.length + compressedArray.length);
      result.set(COMPRESSION_PREFIX);
      result.set(compressedArray, COMPRESSION_PREFIX.length);
      return arrayBufferToHex(result.buffer);
    } else {
      return arrayBufferToHex(uncompressedArray.buffer);
    }
  };

  const handleSubmit = async () => {
    if (!text && !compressedImage) {
      setError('Please enter a message or select an image.');
      return;
    }

    if(!web3) return;
    const network = networkId;
    if(!NETWORKS[Number(network)]) {
      alert("Network not supported");
      return;
    }
    const contract = new web3.eth.Contract(messageFacetABI, NETWORKS[Number(network)].contractAddress);

    setLoading(true);
    try {
      let imageData = null;
      if (compressedImage) {
        if (useIPFS) {
          const added = await ipfs.add(compressedImage);
          imageData = added.path;
        } else {
          const arrayBuffer = await compressedImage.arrayBuffer();
          imageData = Buffer.from(arrayBuffer).toString('base64');
        }
      }

      const messageData = {
        text: text,
        image: imageData
      };

      const preparedMessage = prepareMessageData(messageData);
      const data = '0x' + preparedMessage; // Prefix with '0x' for Ethereum hex string

      if(data.length > 190000) {
        setLoading(false);
        setError('Too much data to upload');
        return;
      }

      if(networkId.toString() === "1") {
        const maxPriorityFeePerGas = web3.utils.toWei('0.1', 'gwei');
        await contract.methods.displayMessage(data).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
      } else if(networkId.toString() === "8453") {
        const maxPriorityFeePerGas = web3.utils.toWei('0.0001', 'gwei');
        await contract.methods.displayMessage(data).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
      } else {
        await contract.methods.displayMessage(data).send({ from: account });
      }

      setText('');
      setImage(null);
      setCompressedImage(null);
      setPreviewImage(null);
      setShowUpload(false);
      
      // Fetch new messages instead of clearing local storage
      handleFetchNewMessages();
    } catch (error) {
      setError('Error sending message: ' + error.message);
    }
    setLoading(false);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  const closeExpandedCard = () => {
    setExpandedMessage(null);
  };

  const renderSenderInfo = (sender) => (
    <SenderInfoContainer>
      <SenderInfo onClick={() => copyToClipboard(sender)}>
        Sender: {sender.substring(0, 6)}...{sender.substring(38)}
      </SenderInfo>
      <CopyIcon size={16} onClick={() => copyToClipboard(sender)} />
    </SenderInfoContainer>
  );

  const handleChainChange = (chain) => {
    if(!chain) return;
    if(chain === 'all') {
      if(!selectedChains.length) {
        const allNetworks = Object.keys(NETWORKS);
        const checkNetworks = [];
        for(let i = 0; i < allNetworks.length; i++) {
          if(!loadedMessageCounts[allNetworks[i]]) {
            checkNetworks.push(allNetworks[i]);
          }
        }
        fetchMessages("initial", checkNetworks);
      }

      setSelectedChains(prev => 
        prev.length === Object.keys(NETWORKS).length 
          ? []
          : Object.keys(NETWORKS)
      );
    } else {
      if(!loadedMessageCounts[chain]) {
        fetchMessages("initial", [chain]);
      }
      setSelectedChains(prev => {
        if (prev.includes(chain)) {
          // If trying to remove the chain
          if (prev.length === 1) {
            // If it's the last selected chain, don't remove it
            return [];
          }
          return prev.filter(c => c !== chain);
        } else {
          // If adding the chain
          return [...prev, chain];
        }
      });
    }
  };


  const renderMessageContent = (message) => {
    const { text, image, type } = message.content;

    return (
      <>
        {text && <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</p>}
        {image && (
          <img className="cardImage"
            src={type === 'ipfs' 
              ? `https://ipfs.io/ipfs/${image}`
              : `data:image/jpeg;base64,${image}`
            } 
            alt={type === 'ipfs' ? "IPFS Image" : "Direct Image"} 
          />
        )}
      </>
    );
  };

  const renderMessageCard = (message, index) => {
    const isOverflowing = overflowingCards[index];
    const network = NETWORKS[message.network];
    const networkColor = network?.color || '#ffffff';
    const networkName = network?.name || 'Unknown Network';

    return (
      <div className='card' key={index}>
        <ChainIndicatorWrapper>
          <ChainIndicator color={networkColor} />
          <ChainTooltip>{networkName}</ChainTooltip>
        </ChainIndicatorWrapper>
        <HeartButton 
          onClick={() => handleToggleUpvote(message.id, message.userUpvoted)} 
          $active={message.userUpvoted}
        >
          <Heart size={20} fill={message.userUpvoted ? '#ff4136' : 'none'} />
          <UpvoteCount>{message.upvotes}</UpvoteCount>
        </HeartButton>
        <div className='cardContent' ref={el => cardRefs.current[index] = el}>
          {renderMessageContent(message)}
        </div>
        <div className='cardFooter'>
          {renderSenderInfo(message.sender)}
          <TimeStamp title={formatDate(message.timestamp)}>
            {getRelativeTimeString(message.timestamp)}
          </TimeStamp>
          <CommentButton onClick={() => handleCardClick(message)}>
            <MessageSquare size={16} />
            {message.commentCount} {message.commentCount === 1 ? 'comment' : 'comments'}
          </CommentButton>
          {isOverflowing && (
            <ContinueReadingButton onClick={() => handleCardClick(message)}>
              Continue reading <ChevronDown size={16} />
            </ContinueReadingButton>
          )}
        </div>
      </div>
    );
  };

  const renderExpandedCard = () => {
    if (!expandedMessage) return null;

    const network = NETWORKS[expandedMessage.network];
    const networkColor = network?.color || '#ffffff';
    const networkName = network?.name || 'Unknown Network';

    return (
      <ExpandedCardOverlay onClick={closeExpandedCard}>
        <ExpandedCard onClick={(e) => e.stopPropagation()}>
          <ChainIndicatorWrapper>
            <ChainIndicator color={networkColor} />
            <ChainTooltip>{networkName}</ChainTooltip>
          </ChainIndicatorWrapper>
          <CloseButton onClick={closeExpandedCard}><X color="white" /></CloseButton>
          <ExpandedHeartButton 
            onClick={() => handleToggleUpvote(expandedMessage.id, expandedMessage.userUpvoted)} 
            $active={expandedMessage.userUpvoted}
          >
            <Heart size={20} fill={expandedMessage.userUpvoted ? '#ff4136' : 'none'} />
            <UpvoteCount>{expandedMessage.upvotes}</UpvoteCount>
          </ExpandedHeartButton>
          <div className='cardContent'>
            {renderMessageContent(expandedMessage)}
          </div>
          <div className='cardFooter'>
            {renderSenderInfo(expandedMessage.sender)}
            <TimeStamp title={formatDate(expandedMessage.timestamp)}>
              {getRelativeTimeString(expandedMessage.timestamp)}
            </TimeStamp>
          </div>
          <CommentSection>
            <h3>Comments ({expandedMessage.commentCount})</h3>
            {hasMoreOlderComments[expandedMessage.id] && (
              <ActionButton onClick={() => handleFetchOlderComments(expandedMessage.id)} disabled={loading}>
                <ChevronDown size={16} />
                Load Older Comments
              </ActionButton>
            )}
              <ActionButton $primary onClick={() => handleSyncComments(expandedMessage.id)} disabled={loading}>
                <ChevronDown size={16} />
                Sync Comments
              </ActionButton>
            <CommentList>
              {expandedMessage.comments ? (
                expandedMessage.comments.map((comment, index) => (
                  <CommentCard key={index}>
                    <p>{comment.content.text}</p>
                    <small>
                      {renderSenderInfo(comment.sender)} • 
                      <span title={formatDate(comment.timestamp)}>
                        {getRelativeTimeString(comment.timestamp)}
                      </span>
                    </small>
                  </CommentCard>
                ))
              ) : (
                <p>Loading comments...</p>
              )}
            </CommentList>
            <InputWrapper>
              <CommentInput
                value={commentText}
                onChange={(e) => setCommentText(e.target.value.slice(0, MAX_COMMENT_LENGTH))}
                placeholder={`Add a comment (max ${MAX_COMMENT_LENGTH} characters)`}
              />
              <EmojiButton onClick={toggleEmojiPicker}>
                <Smile size={20} />
              </EmojiButton>
              {showEmojiPicker && (
                <EmojiPickerContainer>
                  {emojis.map((emoji, index) => (
                    <EmojiButton key={index} onClick={() => handleEmojiClick(emoji)}>
                      {emoji}
                    </EmojiButton>
                  ))}
                </EmojiPickerContainer>
              )}
            </InputWrapper>
            <CommentSubmitButton $primary onClick={() => handleCommentSubmit(expandedMessage.id)} disabled={loading}>
              {loading ? 'Sending...' : 'Comment'}
            </CommentSubmitButton>
          </CommentSection>
        </ExpandedCard>
      </ExpandedCardOverlay>
    );
  };

  const handleCardClick = (message) => {
    setExpandedMessage(message);
    if (!message.comments) {
      fetchComments(message.id);
    }
  };

  const renderUploadBlock = () => (
    <UploadBlock>
      <CloseButton onClick={() => setShowUpload(false)}><X color="white" /></CloseButton>
      <UploadContent>
        <InputSection>
          <SectionTitle>Send a Message</SectionTitle>
          <InputArea>
            <Textarea
              value={text}
              onChange={handleTextChange}
              placeholder={`Enter your message (max ${MAX_TEXT_LENGTH} characters)`}
            />
            <p>{text.length}/{MAX_TEXT_LENGTH} characters</p>
            <Input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
            />
            {image && (
              <>
                <p>Compression Level: {compressionLevel}%</p>
                <Slider
                  value={compressionLevel}
                  onChange={handleCompressionChange}
                  min={0}
                  max={100}
                  step={1}
                />
              </>
            )}
            <SwitchContainer>
              <Switch>
                <input
                  type="checkbox"
                  checked={useIPFS}
                  onChange={handleIPFSToggle}
                />
                <span></span>
              </Switch>
              <SwitchLabel>Use IPFS for image storage</SwitchLabel>
            </SwitchContainer>
          </InputArea>
        </InputSection>
        <PreviewSection>
          <SectionTitle>Preview</SectionTitle>
          <PreviewCard>
          {text && <PreviewText>{text}</PreviewText>}
            {previewImage && <Image src={previewImage} alt="Preview" />}
            <div className='cardFooter'>
              Sender: {account.substring(0, 6)}...{account.substring(38)}
            </div>
          </PreviewCard>
        </PreviewSection>
      </UploadContent>
      <WarningNote>
              Please do not send inappropriate messages or messages containing sensitive data. 
              You are responsible for what you send. Messages violating these guidelines may be deleted.
            </WarningNote>
      <ButtonGroup>
        <Button $secondary onClick={() => setShowUpload(false)}>Cancel</Button>
        <Button $primary onClick={handleSubmit} disabled={loading}>
          {loading ? 'Sending...' : 'Send Message'}
        </Button>
      </ButtonGroup>
    </UploadBlock>
  );

  const renderFilters = () => (
    <div className='filterContainer'>
      <div className='leftGroup'>
        <select className='filterSelect' value={filterType} onChange={(e) => setFilterType(e.target.value)}>
          <option value="all">All Messages</option>
          <option value="text">Text Only</option>
          <option value="images">Images Only</option>
        </select>
        <CustomChainDropdown
          networks={NETWORKS}
          selectedChains={selectedChains}
          onChange={handleChainChange}
          messageCounts={messageCounts}
          loadedMessageCounts={loadedMessageCounts}
        />
        <ActionButton $primary onClick={handleFetchNewMessages} disabled={loading}>
          <RefreshCw size={16} />
          Sync
        </ActionButton>
        <ActionButton $secondary onClick={handleClear} disabled={loading}>
          <X size={16} />
          Clear
        </ActionButton>
      </div>
          
          
      <input className='filterInput'
        type="text"
        placeholder="Filter by sender address"
        value={filterSender}
        onChange={(e) => setFilterSender(e.target.value)}
      />
    </div>
  );

  const filteredMessages = messages.filter(message => {
    if (filterType === 'text' && message.content.image) return false;
    if (filterType === 'images' && !message.content.image) return false;
    if (filterSender && !message.sender.toLowerCase().includes(filterSender.toLowerCase())) return false;
    return true;
  });

  const handleEmojiClick = (emoji) => {
    setCommentText(prevText => prevText + emoji);
    setShowEmojiPicker(false);
  };
  
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  return (
    <div className='scaledContainer'>
      <div className='containerBoard'>
        <div className='titleBoard'>Buzz Board</div>

        {renderFilters()}

        <div className="messageGrid">
          {filteredMessages.map((message, index) => renderMessageCard(message, index))}
        </div>

        {hasMoreOlderMessages && (
          <ActionButton $primary onClick={handleFetchOlderMessages} disabled={loading}>
            <ChevronDown size={16} />
            Load Older Messages
          </ActionButton>
        )}

        <SendButton onClick={() => {if(account) setShowUpload(true)}}>Make a post</SendButton>

        {showUpload && renderUploadBlock()}
        {expandedMessage && renderExpandedCard()}

        {error && <div style={{color: 'red', marginTop: '1rem'}}>{error}</div>}
      </div>
    </div>
  );
};



export default MessageBoardComponent;