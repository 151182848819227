import React, { useState, useEffect, useCallback, useRef, memo } from 'react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import styled from 'styled-components';
import { Info, Plus, Send, MoreVertical, User, Ban, X, Settings as SettingsIcon, ArrowLeft, Upload } from 'lucide-react';
import { Buffer } from 'buffer';
import { secp256k1 } from 'ethereum-cryptography/secp256k1';
import { sha256 } from 'ethereum-cryptography/sha256';
import { hexToBytes, bytesToHex, concatBytes } from 'ethereum-cryptography/utils';
import { useImmer } from 'use-immer';
import './MessengerComponent.css';


// Import the ABI
import ABI from './privateMessageABI.json';

// Styled components
const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
`;

const Card = styled.div` 
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  color: white;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
`;

const Input = styled.input`
  width: 95%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: white;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
  ${props => props.$primary && `
    background-color: #4CAF50;
    color: white;
  `}
  
  ${props => props.$secondary && `
    background-color: #f44336;
    color: white;
  `}
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-bottom: 1rem;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: .4s;
      border-radius: 50%;
    }
  }

  input:checked + span {
    background-color: #2196F3;
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
`;

const SwitchLabel = styled.label`
  margin-left: 10px;
  color: white;
`;

const ErrorMessage = styled.div`
  color: #f44336;
  margin-top: 1rem;
`;

const ValidationMessage = styled.div`
  font-size: 0.8rem;
  margin-top: -0.5rem;
  margin-bottom: 0.5rem;
  color: ${props => props.$isValid ? '#4CAF50' : '#f44336'};
`;

const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
`;

const TooltipText = styled.span`
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;

  ${TooltipContainer}:hover & {
    visibility: visible;
    opacity: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
`;

const LoginCard = styled(Card)`
  max-width: 400px;
`;

// New styled components for the messenger interface
const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const ChatInputContainer = styled.div`
  display: flex;
  padding: 1rem;
`;

const ChatInput = styled(Input)`
  flex: 1;
  margin-bottom: 0;
  margin-right: 1rem;
`;

const MessengerContainer = styled.div`
  display: flex;
  height: 600px;
  width: 800px;
  background-color: #2a2a2a;
  border-radius: 8px;
  overflow: hidden;
`;

const ConversationList = styled.div`
  width: 200px;
  background-color: #3a3a3a;
  display: flex;
  flex-direction: column;
`;

const ConversationScroll = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const ChatWindow = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ChatHeader = styled.div`
  padding: 1rem;
  background-color: #4a4a4a;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionsButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
`;

const NewChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  height: 100%;
`;

const NewChatHeader = styled.h2`
  color: white;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const NewChatInput = styled.input`
  width: 60%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-left: 0rem;
  padding-right: 0rem;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  color: white;
`;

const NewChatTextArea = styled.textarea`
  width: 60%;
  margin-bottom: 1rem;
  margin-left: 0rem;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  color: white;
  resize: vertical;
  min-height: 100px;
`;

const Tooltip = ({ text }) => (
  <TooltipContainer>
    <Info size={16} color="#4a90e2" />
    <TooltipText>{text}</TooltipText>
  </TooltipContainer>
);

// Add this new styled component for the unread indicator
const UnreadIndicator = styled.span`
  width: 10px;
  height: 10px;
  background-color: #4CAF50;
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
`;

// Update the ConversationItem to include the unread indicator
const ConversationItem = styled.div`
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: #4a4a4a;
  }
  ${props => props.$active && `
    background-color: #4a4a4a;
  `}
`;

const NewMessageIndicator = styled.span`
  width: 10px;
  height: 10px;
  background-color: #FFA500; // Orange color to distinguish from unread
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
`;

const MessageBubble = styled.div`
  background-color: ${props => props.$isOwn ? '#4CAF50' : '#3a3a3a'};
  border-radius: 8px;
  padding: 0.5rem 1rem;
  margin-bottom: 0.5rem;
  max-width: 70%;
  margin-left: ${props => props.$isOwn ? 'auto' : '0'};
  margin-right: ${props => props.$isOwn ? '0' : 'auto'};
  position: relative;
`;

const MessageContent = styled.div`
  word-wrap: break-word;
`;

const MessageTimestamp = styled.div`
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.7);
  position: absolute;
  bottom: 2px;
  right: 5px;
`;

const MessageSender = styled.div`
  font-size: 0.8em;
  margin-bottom: 0.2em;
  opacity: 0.7;
`;

const ConversationStartMessage = styled.div`
  text-align: center;
  padding: 10px;
  color: #888;
  font-style: italic;
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
`;

const ProfileName = styled.h2`
  color: white;
  margin-bottom: 0.5rem;
`;

const ProfileDescription = styled.p`
  color: #ccc;
  margin-bottom: 1rem;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #2a2a2a;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  padding: 0.5rem 0;
  z-index: 10;
`;

const DropdownItem = styled.div`
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: #3a3a3a;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const ProfileWindowOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ProfileWindowContent = styled.div`
  background-color: #2a2a2a;
  padding: 2rem;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
  }
`;

const SettingsButton = styled(Button)`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`; 

const ToggleInviteButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
`; 

const SettingsMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const SettingsOption = styled.button`
  background: none;
  border: none;
  color: white;
  padding: 0.5rem;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  &:hover {
    background-color: #3a3a3a;
  }
`;

const GoBackButton = styled(Button)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 10;
`;

const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  padding-right: 5rem;
  color: white;
  position: relative;
`;

const ProfileSection = styled.div`
  margin-bottom: 0rem;
`;

const ProfileHeader = styled.h2`
  margin-bottom: 1rem;
`;

const ProfileRow = styled.div`
  display: flex;
  gap: 2rem;
`;

const ProfileColumn = styled.div`
  flex: 1;
`;

const ProfileField = styled.div`
  margin-bottom: 1rem;
`;

const ProfileLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const ProfileInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  color: white;
`;

const ProfileTextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  color: white;
  resize: vertical;
`;

const ProfilePictureUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const ProfilePicturePreview = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 50%;
`;

const UploadButton = styled(Button)`
  display: flex;
`;


const ConversationHeader = styled.div`
  padding: 1rem;
  font-weight: bold;
  background-color: #4a4a4a;
  border-bottom: 1px solid #5a5a5a;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Create a new styled component for the New Chat button
const NewChatButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const UsernameSettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: white;
  position: relative;
`;

const PrivateSettingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  color: white;
  position: relative;
`;


const UsernameSection = styled.div`
  margin-bottom: 2rem;
`;

const UsernameHeader = styled.h2`
  margin-bottom: 1rem;
`;

const UsernameField = styled.div`
  margin-bottom: 1rem;
`;

const InviteOnlyField = styled.div`
  margin-bottom: 0rem;
  margin-top: 4rem;
`;

const UsernameLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const UsernameInput = styled.input`
  width: 50%;
  padding: 0.5rem;
  background-color: #3a3a3a;
  border: 1px solid #4a4a4a;
  color: white;
`;

const SuccessMessage = styled.div`
  color: #51cf66;
  margin-top: 0.5rem;
`;

const ConfirmationOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ConfirmationDialog = styled.div`
  background-color: #2a2a2a;
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
`;

const ConfirmationMessage = styled.p`
  color: white;
  margin-bottom: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

const ButtonConfirmation = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;

  ${props => props.$primary && `
    background-color: #4CAF50;
    color: white;
  `}

  ${props => props.$secondary && `
    background-color: #f44336;
    color: white;
  `}
`;



// Block Confirmation Dialog Component
const BlockConfirmationDialog = ({ address, onConfirm, onCancel }) => (
  <ConfirmationOverlay>
    <ConfirmationDialog>
      <ConfirmationMessage>
        Are you sure you want to block address: {address}?
      </ConfirmationMessage>
      <ButtonContainer>
        <ButtonConfirmation $secondary onClick={onCancel}>Cancel</ButtonConfirmation>
        <ButtonConfirmation $primary onClick={onConfirm}>Yes</ButtonConfirmation>
      </ButtonContainer>
    </ConfirmationDialog>
  </ConfirmationOverlay>
);



class Node {
    constructor(value) {
      this.value = value;
      this.next = null;
      this.prev = null;
    }
  }
  
  class Deque {
    constructor() {
      this.head = null;
      this.tail = null;
      this.length = 0;
    }
  
    // Add an item at the front of the deque
    addFront(value) {
      const newNode = new Node(value);
      if (this.isEmpty()) {
        this.head = this.tail = newNode;
      } else {
        newNode.next = this.head;
        this.head.prev = newNode;
        this.head = newNode;
      }
      this.length++;
    }
  
    // Add an item at the rear of the deque
    addRear(value) {
      const newNode = new Node(value);
      if (this.isEmpty()) {
        this.head = this.tail = newNode;
      } else {
        newNode.prev = this.tail;
        this.tail.next = newNode;
        this.tail = newNode;
      }
      this.length++;
    }
  
    // Remove an item from the front of the deque
    removeFront() {
      if (this.isEmpty()) {
        return null;
      }
      const value = this.head.value;
      if (this.head === this.tail) {
        this.head = this.tail = null;
      } else {
        this.head = this.head.next;
        this.head.prev = null;
      }
      this.length--;
      return value;
    }
  
    // Remove an item from the rear of the deque
    removeRear() {
      if (this.isEmpty()) {
        return null;
      }
      const value = this.tail.value;
      if (this.head === this.tail) {
        this.head = this.tail = null;
      } else {
        this.tail = this.tail.prev;
        this.tail.next = null;
      }
      this.length--;
      return value;
    }
  
    // Get the item at the front of the deque
    peekFront() {
      return this.isEmpty() ? null : this.head.value;
    }
  
    // Get the item at the rear of the deque
    peekRear() {
      return this.isEmpty() ? null : this.tail.value;
    }
  
    // Check if the deque is empty
    isEmpty() {
      return this.length === 0;
    }
  
    // Get the size of the deque
    size() {
      return this.length;
    }
  
    // Clear the deque
    clear() {
      this.head = this.tail = null;
      this.length = 0;
    }
  
    // Make the Deque iterable
    [Symbol.iterator]() {
      let current = this.head;
      return {
        next: () => {
          if (current) {
            const value = current.value;
            current = current.next;
            return { value, done: false };
          } else {
            return { done: true };
          }
        }
      };
    }
  }

// Move InitializationForm outside the parent component
const InitializationForm = ({
    passwordRef,
    handlePasswordChange,
    passwordValidation,
    confirmPasswordRef,
    handleConfirmPasswordChange,
    confirmValidation,
    useMessageSigning,
    setUseMessageSigning,
    inviteOnly,
    setInviteOnly,
    handleInitialize,
    error,
    account
}) => (
    <Card>
        <Input
            type="password"
            ref={passwordRef}
            onChange={handlePasswordChange}
            placeholder="Enter your password (min 8 chars, 1 uppercase, 1 special char)"
        />
        <ValidationMessage $isValid={passwordValidation.isValid}>
            {passwordValidation.message}
        </ValidationMessage>
        <Input
            type="password"
            ref={confirmPasswordRef}
            onChange={handleConfirmPasswordChange}
            placeholder="Confirm your password"
        />
        <ValidationMessage $isValid={confirmValidation.isValid}>
            {confirmValidation.message}
        </ValidationMessage>
        <SwitchContainer>
            <Switch>
                <input
                    type="checkbox"
                    checked={useMessageSigning}
                    onChange={(e) => setUseMessageSigning(e.target.checked)}
                />
                <span></span>
            </Switch>
            <SwitchLabel>Use message signing</SwitchLabel>
            <Tooltip text="Adds an extra layer of security by using a signed message in key generation" />
        </SwitchContainer>
        <SwitchContainer>
            <Switch>
                <input
                    type="checkbox"
                    checked={inviteOnly}
                    onChange={(e) => setInviteOnly(e.target.checked)}
                />
                <span></span>
            </Switch>
            <SwitchLabel>Invite only</SwitchLabel>
            <Tooltip text="Only receive messages from addresses you select" />
        </SwitchContainer>
        <Button $primary onClick={handleInitialize} disabled={!passwordValidation.isValid || !confirmValidation.isValid}>
            Initialize Messenger
        </Button>
        {error && <ErrorMessage>{error}</ErrorMessage>}
    </Card>
);

const NewChatInterface = memo(({ onStartConversation, web3 }) => {
    const [newChatRecipient, setNewChatRecipient] = useState('');
    const [newChatMessage, setNewChatMessage] = useState('');
    const [localError, setLocalError] = useState('');

    const handleStartNewConversation = useCallback(() => {
        if (!web3.utils.isAddress(newChatRecipient)) {
            setLocalError('Invalid recipient address');
            //return;
        }
        setLocalError('');
        onStartConversation(newChatRecipient, newChatMessage);
        setNewChatRecipient('');
        setNewChatMessage('');
    }, [newChatRecipient, newChatMessage, onStartConversation, web3]);

    return (
        <NewChatContainer>
            <NewChatHeader>Start new conversation</NewChatHeader>
            <NewChatInput
                type="text"
                value={newChatRecipient}
                onChange={(e) => setNewChatRecipient(e.target.value)}
                placeholder="Enter recipient's address"
            />
            <NewChatTextArea
                value={newChatMessage}
                onChange={(e) => setNewChatMessage(e.target.value)}
                placeholder="Type your first message"
            />
            <Button $primary onClick={handleStartNewConversation} disabled={!newChatRecipient || !newChatMessage} style={{ width: '50% '}}>
                <Send size={16} /> Start Conversation
            </Button>
            {localError && <ErrorMessage>{localError}</ErrorMessage>}
        </NewChatContainer>
    );
});

const ProfileWindow = ({ friendAddress, onClose, fetchProfileData }) => {
    const [profileData, setProfileData] = useState(null);
    const [imageUrl, setImageUrl] = useState('/default-profile-picture.jpg');
    const profileRef = useRef(null);
  
    useEffect(() => {
        const loadProfileData = async () => {
          const data = await fetchProfileData(friendAddress);
          setProfileData(data);
          if (data.picture) {
            setImageUrl(hexToImageUrl(data.picture));
          }
        };
        loadProfileData();
    
        const handleClickOutside = (event) => {
          if (profileRef.current && !profileRef.current.contains(event.target)) {
            onClose();
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
          // Clean up the created object URL
          if (imageUrl !== '/default-profile-picture.jpg') {
            URL.revokeObjectURL(imageUrl);
          }
        };
      }, [friendAddress, fetchProfileData, onClose, imageUrl]);

    const hexToImageUrl = (hexString) => {
        if (!hexString || hexString === '0x') return '/default-profile-picture.jpg';
        const buffer = Buffer.from(hexString.slice(2), 'hex');
        const blob = new Blob([buffer], { type: 'image/png' }); // Adjust type if necessary
        return URL.createObjectURL(blob);
      };
  
    if (!profileData) return null;
  
    return (
      <ProfileWindowOverlay>
        <ProfileWindowContent ref={profileRef}>
          <CloseButton onClick={onClose}><X size={20} /></CloseButton>
          <ProfilePicture src={imageUrl} alt={profileData.name} />
          <ProfileName>{profileData.name || 'Anonymous'}</ProfileName>
          <ProfileDescription>{profileData.description || 'No description available.'}</ProfileDescription>
        </ProfileWindowContent>
      </ProfileWindowOverlay>
    );
};

const ProfileFormComponent = ({ currentProfile, onSave, onGoBack, contract, account }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [newPicture, setNewPicture] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
  
    useEffect(() => {
      if (currentProfile) {
        setName(currentProfile.name || '');
        setDescription(currentProfile.description || '');
        setIsLoading(false);
      }
    }, [currentProfile]);
  
    const handlePictureChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const buffer = Buffer.from(reader.result);
          const hexString = bytesToHex(buffer);
          setNewPicture(hexString);
        };
        reader.readAsArrayBuffer(file);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError('');
  
      try {
        const pictureToUse = newPicture ? "0x" + newPicture : '0x';
        onSave({ name, description, picture: pictureToUse });
        setName("");
        setDescription("");
        setNewPicture(null);
      } catch (error) {
        console.error('Error updating profile:', error);
        setError('Failed to update profile. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
  
    const getPictureUrl = (hexString) => {
      if (!hexString || hexString === '0x') return '/default-avatar.png';
      const buffer = hexToBytes(hexString);
      const blob = new Blob([buffer], { type: 'image/png' });
      return URL.createObjectURL(blob);
    };
  
    if (isLoading) {
      return <div>Loading profile...</div>;
    }
  
    return (
      <ProfileForm onSubmit={handleSubmit}>
        <GoBackButton onClick={onGoBack}>
          <ArrowLeft size={16} /> Go Back
        </GoBackButton>
        
        <ProfileSection>
          <ProfileHeader>Profile</ProfileHeader>
          <ProfileRow>
            <ProfileColumn>
              <ProfilePicturePreview src={getPictureUrl(currentProfile?.picture)} alt="Current profile" />
            </ProfileColumn>
            <ProfileColumn>
              <ProfileField>
                <ProfileLabel>Name:</ProfileLabel>
                <div>{currentProfile?.name || 'Not set'}</div>
              </ProfileField>
              <ProfileField>
                <ProfileLabel>Description:</ProfileLabel>
                <div>{currentProfile?.description || 'Not set'}</div>
              </ProfileField>
            </ProfileColumn>
          </ProfileRow>
        </ProfileSection>
  
        <ProfileSection>
          <ProfileHeader>Change Profile</ProfileHeader>
          <ProfileRow>
            <ProfileColumn>
              <ProfilePictureUpload>
                <ProfilePicturePreview src={getPictureUrl(newPicture || currentProfile?.picture)} alt="Profile preview" />
                <UploadButton as="label" htmlFor="picture">
                  <Upload size={16} /> Upload Picture
                  <input
                    id="picture"
                    type="file"
                    accept="image/*"
                    onChange={handlePictureChange}
                    style={{ display: 'none' }}
                  />
                </UploadButton>
              </ProfilePictureUpload>
            </ProfileColumn>
            <ProfileColumn>
              <ProfileField>
                <ProfileLabel htmlFor="name">Name:</ProfileLabel>
                <ProfileInput
                  id="name"
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </ProfileField>
              <ProfileField>
                <ProfileLabel htmlFor="description">Description:</ProfileLabel>
                <ProfileTextArea
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                />
              </ProfileField>
            </ProfileColumn>
          </ProfileRow>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <Button $primary type="submit" disabled={isLoading}>
            {isLoading ? 'Saving...' : 'Save Changes'}
          </Button>
        </ProfileSection>
      </ProfileForm>
    );
  };


  const UsernameSettingsComponent = ({ onGoBack, contract, account }) => {
    const [username, setUsername] = useState('');
    const [newUsername, setNewUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    useEffect(() => {
      const fetchCurrentUsername = async () => {
        try {
          const alias = await contract.methods.myAlias().call({ from: account });
          setUsername(alias || '');
        } catch (error) {
          console.error('Error fetching current username:', error);
          setError('Failed to fetch current username');
        }
      };
      fetchCurrentUsername();
    }, [contract, account]);
  
    const checkUsernameAvailability = async (username) => {
      try {
        const owner = await contract.methods.getAliasOwner(username).call();
        return owner === '0x0000000000000000000000000000000000000000';
      } catch (error) {
        console.error('Error checking username availability:', error);
        throw new Error('Failed to check username availability');
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setIsLoading(true);
      setError('');
      setSuccess('');
  
      try {
        if (username === newUsername) {
          setError('New username must be different from the current one');
          return;
        }
  
        const isAvailable = await checkUsernameAvailability(newUsername);
        if (!isAvailable) {
          setError('Username is already taken');
          return;
        }
  
        if (username) {
          await contract.methods.changeAlias(newUsername).send({ from: account });
        } else {
          await contract.methods.setAliasForAddress(newUsername).send({ from: account });
        }
  
        setSuccess('Username updated successfully!');
      } catch (error) {
        console.error('Error updating username:', error);
        setError('Failed to update username. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <UsernameSettingsForm onSubmit={handleSubmit}>
        <GoBackButton onClick={onGoBack}>
          <ArrowLeft size={16} /> Go Back
        </GoBackButton>
        
        <UsernameSection>
          <UsernameHeader>Current Username</UsernameHeader>
          <UsernameField>
            <div>{username || 'No username set'}</div>
          </UsernameField>
        </UsernameSection>
  
        <UsernameSection>
          <UsernameHeader>Change Username</UsernameHeader>
          <UsernameField>
            <UsernameLabel htmlFor="username">New Username:</UsernameLabel>
            <UsernameInput
              id="username"
              type="text"
              value={newUsername}
              onChange={(e) => setNewUsername(e.target.value)}
              placeholder="Enter new username"
            />
          </UsernameField>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}
          <Button $primary type="submit" disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Update Username'}
          </Button>
        </UsernameSection>
      </UsernameSettingsForm>
    );
  };

  const PrivateSettingsComponent = ({ onGoBack, contract, account, web3 }) => {
    const [inviteOnly, setInviteOnly] = useState(false);
    const [whitelistUsername, setWhitelistUsername] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
  
    useEffect(() => {
      const fetchCurrentToggle = async () => {
        try {
          const currentInviteOnly = await contract.methods.getMessangerSettings(account).call();
          setInviteOnly(currentInviteOnly['0'] || false);
        } catch (error) {
          console.error('Error fetching invite only status:', error);
          setError('Failed to fetch current invite only status');
        }
      };
      fetchCurrentToggle();
    }, [contract, account]);
  
    const handleSubmit = async () => {
      setIsLoading(true);
      setError('');
      setSuccess('');
  
      try {
        if(!inviteOnly) {
          setIsLoading(false);
          setError('Please enable invite only before whitelisting someone');
          return;
        }

        if(!whitelistUsername || !web3.utils.isAddress(whitelistUsername)) {
          setIsLoading(false);
          setError('Please set address to whitelist/ Invalid Address');
          return;
        }

        const currentStatus = await contract.methods.getWhitelisteAndBlocked(whitelistUsername, account).call();
        if(currentStatus["1"]) {
          setIsLoading(false);
          setError('Address is already whitelisted');
          return;
        }
        
        await contract.methods.toggleSettings(0, whitelistUsername, true).send({ from: account });
        setSuccess('Address has been whitelisted.');
      } catch (error) {
        console.error('Error in whitelisting:', error);
        setError('Failed to whistelist address.');
      } finally {
        setIsLoading(false);
      }
    };

    const toogleInviteOnly  = async () => {
      setIsLoading(true);
      setError('');
      setSuccess('');

      if(isLoading) return;

      try {
        await contract.methods.toggleSettings(2, account, !inviteOnly).send({ from: account });
        setSuccess('Invite only has been changed.');
      } catch (error) {
        console.error('Error in setting invite only:', error);
        setError('Failed to set invite only.');
      } finally {
        setIsLoading(false);
      }

    };
  
    return (
      <div className="privateSettingContainer">
        <PrivateSettingContainer>
          <GoBackButton onClick={onGoBack}>
            <ArrowLeft size={16} /> Go Back
          </GoBackButton>


          <InviteOnlyField>
            <div>{"Current invite only setting: " + inviteOnly.toString() || 'Invalid inviteOnly'}</div>
          </InviteOnlyField>

          <ToggleInviteButton $primary onClick={() => toogleInviteOnly()} disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Toggle Invite Only'}
          </ToggleInviteButton>

          <UsernameInput
              id="whitelistAddress"
              type="text"
              value={whitelistUsername}
              onChange={(e) => setWhitelistUsername(e.target.value)}
              placeholder="Enter addresss to whitelist"
            />

          <ToggleInviteButton $primary onClick={() => handleSubmit()} disabled={isLoading}>
            {isLoading ? 'Updating...' : 'Whitelist Address'}
          </ToggleInviteButton>

          {error && <ErrorMessage>{error}</ErrorMessage>}
          {success && <SuccessMessage>{success}</SuccessMessage>}

          
        </PrivateSettingContainer>
      </div>
    );
  };


const MessengerInterface = memo(({
    conversations,
    selectedConversationIndex,
    handleConversationClick,
    setIsNewChat,
    isNewChat,
    handleStartNewConversation,
    web3,
    newMessage,
    setNewMessage,
    handleSendMessage,
    handleScroll,
    fetchProfileData,
    account,
    contract
}) => {
    const [showOptions, setShowOptions] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    const [activeSettingOption, setActiveSettingOption] = useState(null);
    const [currentProfile, setCurrentProfile] = useState(null);
    const [showBlockConfirmation, setShowBlockConfirmation] = useState(false);
    const [addressToBlock, setAddressToBlock] = useState(null);
    const optionsRef = useRef(null);
    const chatMessagesRef = useRef(null);

    // ... (previous useEffects and functions)

    const handleProfileClick = () => {
        setShowProfile(true);
        setShowOptions(false);
    };

    const handleCloseProfile = () => {
        setShowProfile(false);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setShowOptions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const chatMessagesElement = chatMessagesRef.current;
        if (chatMessagesElement) {
            const handleScrollEvent = () => {
                if (chatMessagesElement.scrollTop === 0 &&
                    selectedConversationIndex !== null &&
                    conversations[selectedConversationIndex] &&
                    !conversations[selectedConversationIndex].reachedStart) {
                    handleScroll(selectedConversationIndex);
                }
            };
            chatMessagesElement.addEventListener('scroll', handleScrollEvent);
            return () => chatMessagesElement.removeEventListener('scroll', handleScrollEvent);
        }
    }, [handleScroll, selectedConversationIndex, conversations]);

    const handleSettingsClick = () => {
        setShowSettings(true);
        setIsNewChat(false);
        setActiveSettingOption(null);
    };

    const renderSettingsMenu = () => (
        <SettingsMenu>
            <SettingsOption onClick={() => handleSettingsOptionClick('Profile')}>Profile</SettingsOption>
            <SettingsOption onClick={() => handleSettingsOptionClick('Username')}>Username</SettingsOption>
            <SettingsOption onClick={() => handleSettingsOptionClick('PrivateMode')}>Private Mode</SettingsOption>
        </SettingsMenu>
    );

    const fetchCurrentProfile = useCallback(async () => {
      try {
          const profileData = await contract.methods.getProfile(account).call();
          setCurrentProfile({
              name: profileData.name,
              description: profileData.description,
              picture: profileData.picture
          });
      } catch (error) {
          console.error('Error fetching profile:', error);
          setCurrentProfile({ name: '', description: '', picture: '0x' });
      }
  }, [contract, account]);

    const handleSaveProfile = async (newProfileData) => {
        try {
            await contract.methods.changeProfile(
                newProfileData.picture,
                newProfileData.name,
                newProfileData.description
            ).send({ from: account });

            setCurrentProfile(newProfileData);
            alert('Profile updated successfully!');
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Failed to update profile. Please try again.');
        }
    };

    const handleSettingsOptionClick = useCallback((option) => {
        setActiveSettingOption(option);
        if (option === 'Profile') {
            fetchCurrentProfile();
        }
    }, [fetchCurrentProfile]);

    const renderSettingsContent = () => {
        switch (activeSettingOption) {
            case 'Profile':
                return (
                    <ProfileFormComponent
                        currentProfile={currentProfile}
                        onSave={handleSaveProfile}
                        onGoBack={() => setActiveSettingOption(null)}
                        contract={contract}
                        account={account}
                    />
                );
            case 'Username':
                return (
                    <UsernameSettingsComponent
                        onGoBack={() => setActiveSettingOption(null)}
                        contract={contract}
                        account={account}
                    />
                );
            case 'PrivateMode':
              return (
                <PrivateSettingsComponent 
                onGoBack={() => setActiveSettingOption(null)}
                contract={contract}
                account={account}
                web3={web3}/>
              );
            default:
                return renderSettingsMenu();
        }
    };


    const formatTimestamp = (timestamp) => {
        const date = new Date(parseInt(timestamp) * 1000);
        return date.toLocaleString([], { 
            year: 'numeric', 
            month: 'short', 
            day: 'numeric',
            hour: '2-digit', 
            minute: '2-digit'
        });
    };

    const handleBlockClick = useCallback(() => {
        if (selectedConversationIndex !== null && conversations[selectedConversationIndex]) {
            setAddressToBlock(conversations[selectedConversationIndex].address);
            setShowBlockConfirmation(true);
            setShowOptions(false);
        }
    }, [selectedConversationIndex, conversations]);

    const handleConfirmBlock = useCallback(async () => {
        if (addressToBlock) {
            try {
                // Use toggleSettings function to block the address
                // Assuming 1 is the setting number for blocking
                await contract.methods.toggleSettings(1, addressToBlock, true).send({ from: account });
                alert(`Address ${addressToBlock} has been blocked.`);
                // You might want to update the UI or conversation list here
            } catch (error) {
                console.error('Error blocking address:', error);
                alert('Failed to block address. Please try again.');
            }
        }
        setShowBlockConfirmation(false);
        setAddressToBlock(null);
    }, [addressToBlock, contract, account]);

    const handleCancelBlock = useCallback(() => {
        setShowBlockConfirmation(false);
        setAddressToBlock(null);
    }, []);

    const handleConversationClickInside = (index) => {
        setShowSettings(false);
        handleConversationClick(index);
    };

    const renderMessages = () => {
        if (selectedConversationIndex === null || !conversations[selectedConversationIndex]) {
            return null;
        }

        const conversation = conversations[selectedConversationIndex];
        const messages = conversation.messages;

        if (!messages) {
            console.error('Messages is not a Deque:', messages);
            return <div>No messages to display</div>;
        }

        const messageArray = Array.from(messages);

        return (
            <>
                {conversation.reachedStart && messageArray.length > 0 && (
                    <ConversationStartMessage>
                        Conversation started at {formatTimestamp(messageArray[0].time)}
                    </ConversationStartMessage>
                )}
                {messageArray.map((msg, index) => (
                    <MessageBubble 
                        key={index} 
                        $isOwn={msg.isOwn}
                        $isSystem={msg.isSystem}
                    >
                        {!msg.isSystem && (
                            <MessageSender>
                                {msg.isOwn ? 'You' : `${msg.sender.slice(0, 6)}...${msg.sender.slice(-4)}`}
                            </MessageSender>
                        )}
                        <MessageContent>{msg.content}</MessageContent>
                        <MessageTimestamp>{formatTimestamp(msg.time)}</MessageTimestamp>
                    </MessageBubble>
                ))}
            </>
        );
    };

    return (
        <MessengerContainer>
            <ConversationList>
                <ConversationHeader>
                    <span>Conversations</span>
                    <NewChatButton onClick={() => setIsNewChat(true)}>
                        <Plus size={20} />
                    </NewChatButton>
                </ConversationHeader>
                <ConversationScroll>
                    {conversations.map((conv, index) => (
                        <ConversationItem 
                            key={conv.address} 
                            onClick={() => handleConversationClickInside(index)}
                            $active={selectedConversationIndex === index}
                        >
                            <span>
                                {conv.address === '0x0000000000000000000000000000000000000000' 
                                    ? 'System' 
                                    : `${conv.address.slice(0, 6)}...${conv.address.slice(-4)}`
                                }
                            </span>
                            {conv.unread && <UnreadIndicator />}
                            {conv.hasNewMessages && <NewMessageIndicator />}
                        </ConversationItem>
                    ))}
                </ConversationScroll>
                <SettingsButton $primary onClick={() => handleSettingsClick()}>
                    <SettingsIcon size={16} /> Settings
                </SettingsButton>
            </ConversationList>
            <ChatWindow>
                {isNewChat ? (
                    <NewChatInterface 
                        onStartConversation={handleStartNewConversation}
                        web3={web3}
                    />
                ) : showSettings ? (
                    renderSettingsContent()
                ) : selectedConversationIndex !== null && conversations[selectedConversationIndex] ? (
                    <>
                        <ChatHeader>
                            <span>
                                {conversations[selectedConversationIndex].address === '0x0000000000000000000000000000000000000000' 
                                    ? 'System' 
                                    : (conversations[selectedConversationIndex].alias === "" ?
                                    `${conversations[selectedConversationIndex].address.slice(0, 10)}...${conversations[selectedConversationIndex].address.slice(-8)}`
                                    : `${conversations[selectedConversationIndex].alias} (${conversations[selectedConversationIndex].address.slice(0, 10)}...${conversations[selectedConversationIndex].address.slice(-8)})`)
                                }
                            </span>
                            <div ref={optionsRef} style={{ position: 'relative' }}>
                                <OptionsButton onClick={() => setShowOptions(!showOptions)}>
                                    <MoreVertical size={20} />
                                </OptionsButton>
                                {showOptions && (
                                    <DropdownMenu>
                                        <DropdownItem onClick={handleProfileClick}>
                                            <User size={16} />
                                            Profile
                                        </DropdownItem>
                                        <DropdownItem onClick={handleBlockClick}>
                                            <Ban size={16} />
                                            Block
                                        </DropdownItem>
                                    </DropdownMenu>
                                )}
                            </div>
                        </ChatHeader>
                        <ChatMessages ref={chatMessagesRef}>
                            {renderMessages()}
                        </ChatMessages>
                        <ChatInputContainer>
                            <ChatInput
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type your message"
                                disabled={conversations[selectedConversationIndex].address === '0x0000000000000000000000000000000000000000'}
                            />
                            <Button 
                                $primary 
                                onClick={() => handleSendMessage(selectedConversationIndex)}
                                disabled={conversations[selectedConversationIndex].address === '0x0000000000000000000000000000000000000000'}
                            >
                                <Send size={16} />
                            </Button>
                        </ChatInputContainer>
                    </>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <Button $primary onClick={() => setIsNewChat(true)}>
                            <Plus size={16} /> Start New Conversation
                        </Button>
                    </div>
                )}
            </ChatWindow>
            {showBlockConfirmation && (
                <BlockConfirmationDialog
                    address={addressToBlock}
                    onConfirm={handleConfirmBlock}
                    onCancel={handleCancelBlock}
                />
            )}
            {showProfile && selectedConversationIndex !== null && (
                <ProfileWindow 
                    friendAddress={conversations[selectedConversationIndex].address}
                    onClose={handleCloseProfile}
                    fetchProfileData={fetchProfileData}
                />
            )}
        </MessengerContainer>
    );
});

const SUPPORTED_NETWORKS = [97, 8453, 56, 1];
const CONTRACT_ADDRESSES = {
  97: "0x14B33232fcd0B7C18Dd1eD50dA46ef3Ab0ADCFd3",
  8453: "0x0937d22e6caB92cd3dA981f9a85fCF2F4A907aed",
  56: "0x0937d22e6caB92cd3dA981f9a85fCF2F4A907aed",
  1: "0xa1fF5Cca0FDC74dC0d3a50a18c2C3dc4E9Fc8Ed4"
}

const MessengerComponent = ({web3, account, networkId }) => {
    const [contract, setContract] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [useMessageSigning, setUseMessageSigning] = useState(false);
    const [inviteOnly, setInviteOnly] = useState(false);
    const [error, setError] = useState('');
    const [passwordValidation, setPasswordValidation] = useState({ 
        isValid: false, 
        message: '', 
        hasUpperCase: false,
        hasSpecialChar: false,
        hasMinLength: false
    });
    const [confirmValidation, setConfirmValidation] = useState({ isValid: false, message: '' });
    const [conversations, updateConversations] = useImmer([]);
    const [selectedConversationIndex, setSelectedConversationIndex] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [isNewChat, setIsNewChat] = useState(false);
    const [isNetworkSupported, setIsNetworkSupported] = useState(true);
    const [isConnected, setIsConntected] = useState(true);


    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);
    const currentEncrypted = useRef({});
  
    useEffect(() => {
      if (!SUPPORTED_NETWORKS.includes(Number(networkId))) {
        setIsNetworkSupported(false);
        setError('Network not supported');
        return; // Exit early if network is not supported
      }

      setIsNetworkSupported(true);
      setError('');
 
      if(!web3 || !account) {
        setIsConntected(false);
        return;
      }

      setIsConntected(true);

      const initWeb3 = async () => {
        try {
          const contractInstance = new web3.eth.Contract(ABI.ABI, CONTRACT_ADDRESSES[Number(networkId)]);
          setContract(contractInstance);
          checkInitialization(account, contractInstance);
        } catch (error) {
          console.error('Failed to use Web3:', error);
        }
      };
      
      initWeb3();
    }, [account, networkId]);

    const secureDispose = useCallback((data) => {
      if (typeof data === 'string') {
        // Overwrite string with random data
        for (let i = 0; i < data.length; i++) {
          data = data.slice(0, i) + String.fromCharCode(Math.floor(Math.random() * 94 + 33)) + data.slice(i + 1);
        }
      } else if (data instanceof Uint8Array && data.byteLength > 0) {
        // Overwrite Uint8Array with random data
        crypto.getRandomValues(data);

        // Overwrite again with ones
        data.fill(1);

        // Overwrite again with zeros
        data.fill(0);

        // Create a new Uint8Array with zero length
        // This doesn't affect the original reference, but helps in garbage collection
        data = new Uint8Array(0);
      } else if (data instanceof ArrayBuffer) {
          // Create a view of the ArrayBuffer
          const view = new Uint8Array(data); 
          view.fill(1);
          view.fill(0);
          data = null;
      }
      // Set to null to help with garbage collection
      data = null;
      return null;
  }, []);

    // Function to securely delete passwords
    const securelyDeletePasswords = useCallback(() => {
        if (passwordRef.current) {
            passwordRef.current.value = secureDispose(passwordRef.current.value);
            passwordRef.current.value = '';
        }
        if (confirmPasswordRef.current) {
            confirmPasswordRef.current.value = secureDispose(confirmPasswordRef.current.value);
            confirmPasswordRef.current.value = '';
        }
    }, [secureDispose]);

    // Cleanup effect
    useEffect(() => {
        return () => {
            securelyDeletePasswords();
        };
    }, [securelyDeletePasswords]);

  
    const validatePassword = useCallback(() => {
        const pass = passwordRef.current.value;
        const hasUpperCase = /[A-Z]/.test(pass);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);
        const hasMinLength = pass.length >= 8;

        const isValid = hasUpperCase && hasSpecialChar && hasMinLength;

        let message = '';
        if (!hasMinLength) message += 'Password must be at least 8 characters long. ';
        if (!hasUpperCase) message += 'Include an uppercase letter. ';
        if (!hasSpecialChar) message += 'Include a special character. ';

        if (isValid) {
            message = 'Password is valid';
        }

        setPasswordValidation({ 
            isValid, 
            message, 
            hasUpperCase, 
            hasSpecialChar, 
            hasMinLength 
        });
    }, []);

    const validateConfirmPassword = useCallback(() => {
        const password = passwordRef.current.value;
        const confirmPass = confirmPasswordRef.current.value;
        const isValid = confirmPass === password;
        const message = isValid ? 'Passwords match' : 'Passwords do not match';
        setConfirmValidation({ isValid, message });
    }, []);

    const handlePasswordChange = useCallback(() => {
        validatePassword();
        validateConfirmPassword();
    }, [validatePassword, validateConfirmPassword]);

    const handleConfirmPasswordChange = useCallback(() => {
        validateConfirmPassword();
    }, [validateConfirmPassword]);
  
    const checkInitialization = async (address, contractInstance) => {
      try {
        const settings = await contractInstance.methods.getMessangerSettings(address).call();
        setIsInitialized(settings[1].length > 2);
      } catch (error) {
        console.error('Error checking initialization:', error);
      }
    };
  
    // derive a random key from a password
    async function deriveKey(password, salt, iterations = 600000) {
        const encoder = new TextEncoder();
        let passwordData = encoder.encode(password);
        const saltData = hexToBytes(salt);
      
        try {
            const keyMaterial = await crypto.subtle.importKey(
              'raw',
              passwordData,
              { name: 'PBKDF2' },
              false,
              ['deriveBits']
            );
        
            const derivedBits = await crypto.subtle.deriveBits(
              {
                name: 'PBKDF2',
                salt: saltData,
                iterations: iterations,
                hash: 'SHA-256'
              },
              keyMaterial,
              256 // 256 bits
            );
        
            return new Uint8Array(derivedBits);
          } finally {
            // Securely delete the password data
            passwordData = secureDispose(passwordData);
          }
    }

    // derive a symmetric key returns a cryptoKey 
    const deriveKeySymmetric = useCallback(async (seed, salt, info, extractable) => {
        let cleanSeed, seedBytes, keyMaterial;
        try {
            if(typeof(seed) === 'string') {
                cleanSeed = seed.startsWith('0x') ? seed.slice(2) : seed;
        
                // Convert hex signature to Uint8Array
                seedBytes = new Uint8Array(cleanSeed.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
            } else {
                seedBytes = seed;
            }
            

            keyMaterial = await crypto.subtle.importKey(
                'raw',
                seedBytes,
                { name: 'HKDF' },
                false,
                ['deriveKey']
            );
            const derivedKey =  await crypto.subtle.deriveKey(
            {
                name: 'HKDF',
                salt: new TextEncoder().encode(salt),
                info: new TextEncoder().encode(info),
                hash: 'SHA-256',
            },
                keyMaterial,
                { name: 'AES-GCM', length: 256 },
                extractable,
                ['encrypt', 'decrypt']
            );
            cleanSeed = secureDispose(cleanSeed);
            seedBytes = secureDispose(seedBytes);
            keyMaterial = secureDispose(keyMaterial);
            return derivedKey;
        } finally {
            cleanSeed = secureDispose(cleanSeed);
            seedBytes = secureDispose(seedBytes);
            keyMaterial = secureDispose(keyMaterial);
        }
      }, [secureDispose]);
    
      const generateOrRegenerateKeyPair = async (password, account) => {
        let derivedKey, entropy, signedMessage, signedMessageBytes;
        try {
            const salt = ethers.hexlify(ethers.getBytes(account));
            derivedKey = await deriveKey(password, salt);
            
            entropy = derivedKey;
            
            if (useMessageSigning) {
              try {
                const message = 'Generate master encryption keys for secure messaging' + account;
                const messageHash = web3.utils.sha3(message);
                signedMessage = await web3.eth.personal.sign(messageHash, account, "");
                signedMessageBytes = ethers.getBytes(signedMessage);
                
                // Combine derived key and signed message using XOR
                entropy = new Uint8Array(derivedKey.length);
                for (let i = 0; i < entropy.length; i++) {
                  entropy[i] = derivedKey[i] ^ signedMessageBytes[i % signedMessageBytes.length];
                }
              } catch (error) {
                console.error('Error signing message:', error);
                throw new Error('Failed to sign message. Please try again.');
              }
            }
            
            // Generate private key
            const privateKeyBytes = sha256(entropy);
            const privateKey = bytesToHex(privateKeyBytes);
        
            // Ensure the private key is valid for secp256k1
            if (!secp256k1.utils.isValidPrivateKey(privateKeyBytes)) {
              throw new Error('Generated private key is not valid for secp256k1');
            }
        
            // Generate public key
            const publicKeyBytes = secp256k1.getPublicKey(privateKeyBytes, true); // true for compressed format
            
            return {
              publicKey: '0x' + bytesToHex(publicKeyBytes),
              privateKey: '0x' + privateKey
            };
          } finally {
            // Securely try to delete all sensitive data
            derivedKey = secureDispose(derivedKey);
            entropy = secureDispose(entropy);
            signedMessage = secureDispose(signedMessage);
            signedMessageBytes = secureDispose(signedMessageBytes);
          }
      };


      // Padding function
      function padMessage(message, blockSize) {
        const padding = blockSize - (message.length % blockSize);
        const paddingBytes = new Uint8Array(padding).fill(padding);
        return concatBytes(message, paddingBytes);
      }

      function unpadMessage(paddedMessage) {
        const padding = paddedMessage[paddedMessage.length - 1];
        return paddedMessage.slice(0, paddedMessage.length - padding);
      }

      function arrayBufferToHex(buffer) {
        return [...new Uint8Array(buffer)]
            .map(x => x.toString(16).padStart(2, '0'))
            .join('');
    }

    function hexToUint8Array(hexString) {
        // Remove '0x' prefix if present
        const cleanHexString = hexString.startsWith('0x') ? hexString.slice(2) : hexString;
    
        // Ensure the cleaned hex string has an even number of characters
        if (cleanHexString.length % 2 !== 0) {
            throw new Error('Invalid hex string: must have an even number of characters');
        }
    
        return new Uint8Array(
            cleanHexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16))
        );
    }
      
        const getEncryptedKeyByType = async (userId, keyType) => {
            return currentEncrypted.current;
        };

        // NOT DONE
        const storeEncryptedKey = async (userId, encryptedBytes, keyType, timeStamp) => {
            currentEncrypted.current = {
                userId: userId,
                encryptedKey: encryptedBytes,
                keyType: keyType,
                timeStamp: timeStamp
            }
        };
        
        const encryptDataIntoStorage = async (data, signature, account, timeStamp, keyType) => {
            let symmKey;
            try {
                symmKey = await deriveKeySymmetric(sha256(hexToBytes(signature)), account + timeStamp, keyType, false);
                const encryptedBytes = await encryptSymmetric(symmKey, data);
                symmKey = secureDispose(symmKey);
                data = secureDispose(data);
                await storeEncryptedKey(account, encryptedBytes, keyType, timeStamp);
            } catch(error) {
                console.log("Error in encryptDataIntoStorage", error);
            } finally {
                symmKey = secureDispose(symmKey);
                data = secureDispose(data);
            }
        };

    const encryptSymmetric = useCallback(async (symmetricKey, data) => {
        let messageBytes, paddedMessage;
        try {
            // Generate a random IV
            const iv = crypto.getRandomValues(new Uint8Array(12));
            
            // encode data into a padded Uint8Array
            const encoder = new TextEncoder();
            messageBytes = encoder.encode(data);
            paddedMessage = padMessage(messageBytes, 16); // AES block size is 16 bytes

            const encryptedData = await crypto.subtle.encrypt(
                { name: 'AES-GCM', iv: iv },
                symmetricKey,
                paddedMessage
            );

            messageBytes = secureDispose(messageBytes);
            paddedMessage = secureDispose(paddedMessage);
            // return encryptedData and iv in hex string
            return {
                encryptedData: arrayBufferToHex(encryptedData),
                iv: arrayBufferToHex(iv)
            }
              
        } catch (error) {
            console.log("Error in encryptSymmetric", error);
        } finally {
            messageBytes = secureDispose(messageBytes);
            paddedMessage = secureDispose(paddedMessage);
        }
      }, [secureDispose]);

      const decryptSymmetric = useCallback(async (symmetricKey, data) => {
        let decryptedData;
        try {
            // Generate IV
            const iv = hexToUint8Array(data.iv);
            
            // get message bytes
            const messageBytes = hexToUint8Array(data.encryptedData);

            decryptedData = await crypto.subtle.decrypt(
                { name: 'AES-GCM', iv: iv },
                symmetricKey,
                messageBytes
            );

            const decoder = new TextDecoder();

            // return encryptedData and iv in hex string
            return decoder.decode(unpadMessage(new Uint8Array(decryptedData)));
              
        } catch (error) {
          console.log("Error in decryptSymmetric", error);
        } finally {
          decryptedData = secureDispose(decryptedData);
        }
      }, [secureDispose]);

      const encryptPrivateKey = async (privateKey, account) => {
        let signedMessage, symmKey;
        try {
            if (!account) {
            throw new Error('No account connected. Please ensure your wallet is connected.');
            }

            // Get the signer
            const message = 'The signature is used to encrypt your password and save it on the blockchain as a backup in case you lose it. NEVER sign this message somewhere else, NEVERccc!';
            signedMessage = await web3.eth.personal.sign(message, account, "");

            symmKey = await deriveKeySymmetric(sha256(hexToBytes(signedMessage)), account, "Backup Key", false);

            const encrypted = await encryptSymmetric(symmKey, privateKey);
            return encrypted;
        } catch (error) {
            console.error('Error encrypting private key:', error);
            throw new Error(`Failed to encrypt private key: ${error.message}`);
        } finally {
            signedMessage = secureDispose(signedMessage);
        }
    };
    
      const handleInitialize = async () => {
        if (!passwordValidation.isValid || !confirmValidation.isValid) {
          setError('Please ensure your password is valid and matches the confirmation');
          return;
        }
    
        if (!account) {
          setError('No account connected. Please ensure your wallet is connected.');
          return;
        }
    
        let password, signedMessage;
        try {
          setError(''); // Clear any previous errors

          password = passwordRef.current.value;
          let {publicKey, privateKey} = await generateOrRegenerateKeyPair(password, account);
          password = secureDispose(password);
          securelyDeletePasswords();
    
          const encryptedPrivateKey = await encryptPrivateKey(privateKey, account);

          const timeStamp = Date.now();
          const message = `The signature is used to encrypt/ decrypt sensitive information like your password. Timestamp: ${timeStamp}`;
          signedMessage = await web3.eth.personal.sign(message, account, "");
          // encrypt the private key with signedMessage and put it into storage and then delete the information
          

          await encryptDataIntoStorage(privateKey, signedMessage, account, timeStamp.toString(), "Master");
          // currentSigniture.current = signedMessage;
          signedMessage = secureDispose(signedMessage);
          privateKey = secureDispose(privateKey);

          const encryptedPrivateKeyHex = web3.utils.asciiToHex(JSON.stringify(encryptedPrivateKey));
    
          await contract.methods.initializeMessenger(
            publicKey,
            encryptedPrivateKeyHex,
            inviteOnly
          ).send({ from: account });
    
          setIsInitialized(true);
        } catch (error) {
          console.error('Initialization error:', error);
          setError(`Error initializing messenger: ${error.message}`);
        } finally {
            signedMessage = secureDispose(signedMessage);
            password = secureDispose(password);
            securelyDeletePasswords();
        }
      };
  
      const handleLogin = async () => {
        let password;
        try {
            if (!account) {
                throw new Error('No account connected. Please ensure your wallet is connected.');
            }

            let password = passwordRef.current.value;
            let {publicKey, privateKey} = await generateOrRegenerateKeyPair(password, account);
            password = secureDispose(password);

            // Verify the public key against the one stored in the contract
            const contractPublicKey = await contract.methods.getMessangerSettings(account).call();
            if (contractPublicKey[1] !== publicKey) {
                throw new Error('Invalid password or public key mismatch.');
            }

            const timeStamp = Date.now();
            const message = `The signature is used to encrypt/ decrypt sensitive information like your password. Timestamp: ${timeStamp}`;
            const signedMessage = await web3.eth.personal.sign(message, account, "");
            // encrypt the private key with signedMessage and put it into storage and then delete the information
            await encryptDataIntoStorage(privateKey, signedMessage, account, timeStamp.toString(), "Master");
            privateKey = secureDispose(privateKey);
            setIsLoggedIn(true);
            fetchConversations();
        } catch (error) {
            console.error('Login error:', error);
            setError(`Error logging in: ${error.message}`);
        } finally {
            secureDispose(password);
            securelyDeletePasswords();
        }
    };

    const fetchConversations = useCallback(async () => {
        try {
            let openConversations = await contract.methods.getOpenConversations().call({ from: account });
            openConversations = [...new Set(openConversations)];

            const conversationDetails = await Promise.all(openConversations.map(async (address) => {
                const details = await contract.methods.getConversationDetails(address).call({ from: account });
                const addressAlias = await contract.methods.viewAliasOfAddress(address).call();
                const lastSender = details["2"];
                return {
                    address: address,
                    alias: addressAlias || "",
                    length: parseInt(details["0"]),
                    lastTimestamp: parseInt(details["1"]),
                    lastSender: lastSender,
                    fromIndex: -1,
                    isInitialized: false,
                    reachedStart: false,
                    unread: lastSender.toLowerCase() !== account.toLowerCase() && lastSender !== '0x0000000000000000000000000000000000000000',
                    key: null,
                    messages: [] 
                };
            }));

            updateConversations(draft => {
                // Clear existing conversations
                draft.length = 0;
                // Add new conversations
                conversationDetails.forEach(conversation => {
                    draft.push({
                        ...conversation,
                        messages: new Deque() // Convert back to Deque when adding to state
                    });
                });
            });
        } catch (error) {
            console.error('Error fetching conversations:', error);
            setError('Failed to fetch conversations.');
        }
    }, [contract, account, updateConversations]);

    const getNewConversationKey = useCallback(async (friendAddress) => {
        let privateKey, sharedSecret;
        try {
            const recipientSettings = await contract.methods.getMessangerSettings(friendAddress).call();
            if (recipientSettings["1"] === "0x") {
                console.log("Recipient is not registered");
                throw new Error("Recipient is not registered");
            }

            const encryptedPrivateKey = await getEncryptedKeyByType(account, "Master");
            if (!encryptedPrivateKey) {
                throw new Error("Master key not found");
            }

            const message = `The signature is used to encrypt/ decrypt sensitive information like your password. Timestamp: ${encryptedPrivateKey.timeStamp}`;
            const signature = await web3.eth.personal.sign(message, account, "");
            const decryptionKey = await deriveKeySymmetric(sha256(hexToBytes(signature)), account + encryptedPrivateKey.timeStamp, "Master", false);

            privateKey = await decryptSymmetric(decryptionKey, encryptedPrivateKey.encryptedKey);

            sharedSecret = secp256k1.getSharedSecret(hexToBytes(privateKey), hexToBytes(recipientSettings["1"]));
            privateKey = secureDispose(privateKey);

            const salt = BigInt(account) < BigInt(friendAddress) ? account + friendAddress : friendAddress + account;
            const symmetricKey = await deriveKeySymmetric(sharedSecret, salt, "Conversation", true);
            sharedSecret = secureDispose(sharedSecret);
            return symmetricKey;
        } catch (error) {
            console.error("Error in getNewConversationKey:", error);
            throw error;
        } finally {
            sharedSecret = secureDispose(sharedSecret);
            privateKey = secureDispose(privateKey);
        }
    }, [account, contract, web3, secureDispose, decryptSymmetric, deriveKeySymmetric]);

    const getConversationKey = useCallback(async (conversationIndex) => {
        if (conversationIndex < 0 || conversationIndex >= conversations.length) {
            throw new Error("Invalid conversation index");
        }
    
        const conversation = conversations[conversationIndex];
        
        if (conversation.key) {
            return conversation.key;
        }
    
        try {
            const friendAddress = conversation.address;
            const newKey = await getNewConversationKey(friendAddress);
            
            updateConversations(draft => {
                draft[conversationIndex].key = newKey;
            });
    
            return newKey;
        } catch (error) {
            console.error("Error getting conversation key:", error);
            throw error;
        }
    }, [conversations, getNewConversationKey, updateConversations]);

    const updateConversationsCallback = useCallback((currentConversation, processedMessages, from) => {
        updateConversations(draft => {
          const draftConversation = draft[currentConversation];
          processedMessages.forEach(msg => draftConversation.messages.addRear(msg));
          draftConversation.fromIndex = from;
          draftConversation.isInitialized = true;
        });
      }, [updateConversations]);

    const fetchNewMessages = useCallback(async (currentConversation, from, to) => {
        let symmetricKey;
        try {
            const friendAddress = conversations[currentConversation].address;


            const messages = await contract.methods.getConversationMessages(
                friendAddress, 
                from, 
                to
            ).call({ from: account });

            const processedMessages = await Promise.all(messages.map(async message => {
              let decryptedContent = web3.utils.hexToUtf8(message["0"]);
              if(friendAddress !== "0x0000000000000000000000000000000000000000") {
                symmetricKey = await getConversationKey(currentConversation);
                decryptedContent = await decryptSymmetric(symmetricKey, JSON.parse(web3.utils.hexToUtf8(message["0"])));
              } 

              return {
                content: decryptedContent,
                isSystem: friendAddress === "0x0000000000000000000000000000000000000000",
                isOwn: message["1"].toLowerCase() === account.toLowerCase(),
                sender: message["1"],
                time: message["2"]
              };
            }));

            // Update state with processed messages
            updateConversationsCallback(currentConversation, processedMessages, from);

        } catch (error) {
            console.error('Error fetching new messages:', error);
        } finally {
            symmetricKey = null;
        }
    }, [conversations, contract, account, web3, getConversationKey, updateConversationsCallback, decryptSymmetric]);

    const fetchMoreMessages = useCallback(async (currentConversation) => {
        let symmetricKey;
        try {
            const conversation = conversations[currentConversation];
            if (conversation.fromIndex === 0 || conversation.fromIndex === -1) {
                console.log("No more messages available");
                return;
            }

            const friendAddress = conversation.address;
            const fromIndex = conversation.fromIndex;
            const toIndex = fromIndex > 10 ? fromIndex - 10 : 0;

            const messages = await contract.methods.getConversationMessages(friendAddress, toIndex, fromIndex).call({ from: account });
            
            const processedMessages = await Promise.all(messages.map(async message => {
              let decryptedContent = web3.utils.hexToUtf8(message["0"]);
              if(friendAddress !== "0x0000000000000000000000000000000000000000") {
                symmetricKey = await getConversationKey(currentConversation);
                decryptedContent = await decryptSymmetric(symmetricKey, JSON.parse(web3.utils.hexToUtf8(message["0"])));
              } 

              return {
                content: decryptedContent,
                isSystem: friendAddress === "0x0000000000000000000000000000000000000000",
                isOwn: message["1"].toLowerCase() === account.toLowerCase(),
                sender: message["1"],
                time: message["2"]
              };
            }));

            // Update state with processed messages
            updateConversations(draft => {
                const draftConversation = draft[currentConversation];
                processedMessages.reverse().forEach(msg => draftConversation.messages.addFront(msg));
                draftConversation.fromIndex = toIndex;
                draftConversation.isInitialized = true;
                draftConversation.reachedStart = toIndex === 0;
            });
        } catch (error) {
            console.log("Error in fetch more messages", error);
        } finally {
            symmetricKey = null;
        }
    }, [conversations, contract, account, web3, getConversationKey, updateConversations, decryptSymmetric]);

    const handleScroll = useCallback((conversationIndex) => {
        if (conversationIndex !== null) {
            fetchMoreMessages(conversationIndex);
        }
    }, [fetchMoreMessages]);

    const checkForNewMessages = useCallback(async () => {
        console.log("Checking for new messages");
        for (let index = 0; index < conversations.length; index++) {
            const conversation = conversations[index];
            try {
                const newDetails = await contract.methods.getConversationDetails(conversation.address).call({ from: account });
                const newLength = parseInt(newDetails["0"]);
                const from = conversation.length;

                if (newLength > conversation.length) {
                    // Only update if there are new messages
                    updateConversations(draft => {
                        const draftConversation = draft[index];
                        draftConversation.length = newLength;
                        draftConversation.lastTimestamp = parseInt(newDetails["1"]);
                        draftConversation.lastSender = newDetails["2"];
                        
                        // Set unread flag if the last message is not from the current user
                        if (draftConversation.lastSender.toLowerCase() !== account.toLowerCase()) {
                            draftConversation.unread = true;
                        }
                    });

                    // Fetch new messages only if the conversation is initialized
                    if (conversation.isInitialized) {
                        await fetchNewMessages(index, from, newLength);
                    }
                }
            } catch (error) {
                console.error(`Error checking for new messages in conversation ${conversation.address}:`, error);
            }
        }
    }, [conversations, contract, account, fetchNewMessages, updateConversations]);

    const firstFetchMessages = useCallback(async (currentConversation) => {
        let symmetricKey;
        try {

            const from = conversations[currentConversation].length > 10 ? conversations[currentConversation].length - 10 : 0;
            const friendAddress = conversations[currentConversation].address;
            const messages = await contract.methods.getConversationMessages(friendAddress, from, conversations[currentConversation].length).call({ from: account });

            const processedMessages = await Promise.all(messages.map(async message => {
              let decryptedContent = web3.utils.hexToUtf8(message["0"]);
              if(friendAddress !== "0x0000000000000000000000000000000000000000") {
                symmetricKey = await getConversationKey(currentConversation);
                decryptedContent = await decryptSymmetric(symmetricKey, JSON.parse(web3.utils.hexToUtf8(message["0"])));
              } 

              return {
                content: decryptedContent,
                isSystem: friendAddress === "0x0000000000000000000000000000000000000000",
                isOwn: message["1"].toLowerCase() === account.toLowerCase(),
                sender: message["1"],
                time: message["2"]
              };
            }));

            updateConversationsCallback(currentConversation, processedMessages, from);

        } catch (error) {
            console.error('Error fetching messages:', error);
            setError('Failed to fetch messages.');
        } finally {
            symmetricKey = secureDispose(symmetricKey);
        }
    }, [conversations, contract, account, web3, getConversationKey, updateConversationsCallback, setError, decryptSymmetric, secureDispose]);

    const handleConversationClick = useCallback(async (conversationIndex) => {
        try {
            setIsNewChat(false);
            setSelectedConversationIndex(conversationIndex);

            updateConversations(draft => {
                const conversation = draft[conversationIndex];
                conversation.unread = false;
            });

            if (!conversations[conversationIndex].isInitialized) {
                console.log("First time", conversationIndex)
                await firstFetchMessages(conversationIndex);
                updateConversations(draft => {
                    draft[conversationIndex].isInitialized = true;
                });
            }
        } catch (error) {
            console.log("error in handleConversationClick", error);
        }

    }, [conversations, updateConversations, firstFetchMessages]);

    const handleSendMessage = async (conversationIndex) => {
        if (!newMessage || !conversationIndex) {
            setError('Please enter a message and select a conversation.');
            return;
        }

        try {
            const friendAddress = conversations[conversationIndex].address;
            // check block status
            const isWhitelisted = await contract.methods.getWhitelisteAndBlocked(account, friendAddress).call();
            if(isWhitelisted["1"] === true) {
                console.log("Recipient blocked you");
                return;
            }


            // Generate a unique conversation key
            let conversationKey = await getConversationKey(conversationIndex);
            // encrypt message
            const encryptedMessage = await encryptSymmetric(conversationKey, newMessage);

            conversationKey = null;

            // Here you would typically encrypt the message before sending
            if(networkId.toString() === "1") {
              const maxPriorityFeePerGas = web3.utils.toWei('0.1', 'gwei');
              await contract.methods.submitMessage(friendAddress, web3.utils.asciiToHex(JSON.stringify(encryptedMessage))).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
            } else if(networkId.toString() === "8453") {
              const maxPriorityFeePerGas = web3.utils.toWei('0.0001', 'gwei');
              await contract.methods.submitMessage(friendAddress, web3.utils.asciiToHex(JSON.stringify(encryptedMessage))).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas });
            } else {
              await contract.methods.submitMessage(friendAddress, web3.utils.asciiToHex(JSON.stringify(encryptedMessage))).send({ from: account });
            }

            
            setNewMessage('');
            checkForNewMessages();
        } catch (error) {
            console.error('Send message error:', error);
            setError(`Error sending message: ${error.message}`);
        }
    };

    const handleStartNewConversation = useCallback(async (recipient, message) => {
        try {
            // check if there is alrady a conversation:
            const conversationLength = await contract.methods.getConversationDetails(recipient).call({ from: account });
            if(conversationLength["0"] !== 0) {
                console.log("Conversation already there");
                return;
            }

            // get recipients key and check if we can send messages to them
            const recipientPublicKey = await contract.methods.getMessangerSettings(recipient).call();
            if(recipientPublicKey["1"] === "0x") {
                console.log("recipient is not registered");
                return;
            }

            // check if account is whitelisted if recepient has inviteOnly activated
            if(recipientPublicKey["0"] === true) {
                const isWhitelisted = await contract.methods.getWhitelisteAndBlocked(account, recipient).call();
                if(isWhitelisted["0"] === false || isWhitelisted["1"] === true) {
                    console.log("You are not whitelisted or you are blocked");
                    return;
                }
            }

            // Generate a unique conversation key
            let conversationKey = await getNewConversationKey(recipient);
            // encrypt message
            const encryptedMessage = await encryptSymmetric(conversationKey, message);
            conversationKey = null;
        
            // send transaction
            // Call the smart contract to start the conversation
            if(networkId.toString() === "1") {
              const maxPriorityFeePerGas = web3.utils.toWei('0.1', 'gwei');
              await contract.methods.startConversation(
                recipient,
                web3.utils.asciiToHex(JSON.stringify(encryptedMessage)),
                web3.utils.asciiToHex("0")
            ).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas  });
            } else if(networkId.toString() === "8453") {
              const maxPriorityFeePerGas = web3.utils.toWei('0.0001', 'gwei');
              await contract.methods.startConversation(
                recipient,
                web3.utils.asciiToHex(JSON.stringify(encryptedMessage)),
                web3.utils.asciiToHex("0")
            ).send({ from: account, maxPriorityFeePerGas: maxPriorityFeePerGas  });
            } else {
              await contract.methods.startConversation(
                recipient,
                web3.utils.asciiToHex(JSON.stringify(encryptedMessage)),
                web3.utils.asciiToHex("0")
            ).send({ from: account });
            }

            // Reset state and fetch conversations
            setIsNewChat(false);
            fetchConversations();
        } catch (error) {
            console.error('Error starting new conversation:', error);
            setError(`Failed to start new conversation: ${error.message}`);
        } 
    }, [contract, account, web3, encryptSymmetric, fetchConversations, getNewConversationKey]);

    const fetchProfileData = useCallback(async (address) => {
        const profileData = await contract.methods.getProfile(address).call();

        return {
            picture: profileData.picture,
            name: `User ${profileData.name}`,
            description: `Description ${profileData.description}`
        };
    }, [contract]);
  
    const LoginForm = () => (
        <LoginCard>
            <h2>Login to Messenger</h2>
            <Input
                type="password"
                ref={passwordRef}
                placeholder="Enter your password"
            />
            <SwitchContainer>
                <Switch>
                    <input
                        type="checkbox"
                        checked={useMessageSigning}
                        onChange={(e) => setUseMessageSigning(e.target.checked)}
                    />
                    <span></span>
                </Switch>
                <SwitchLabel>Use message signing</SwitchLabel>
                <Tooltip text="Adds an extra layer of security by using a signed message in key generation" />
            </SwitchContainer>
            <Button $primary onClick={handleLogin}>
                Login
            </Button>
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </LoginCard>
    );

    return (
      <Container>
          {!isNetworkSupported ? (
              <ErrorMessage>Network not supported. Please switch to a supported network.</ErrorMessage>
          ) : (
            <>
            <h1 className='titleMessenger'>{isInitialized ? (isLoggedIn ? 'Messenger' : 'Login to Messenger') : 'Initialize Messenger'}</h1>
            {account ? (
                <p>Connected Account: {account} on Network {networkId.toString()}</p>
            ) : (
                <p>No account connected. Please connect your wallet.</p>
            )}
            {!isInitialized ? (
                <InitializationForm
                    passwordRef={passwordRef}
                    handlePasswordChange={handlePasswordChange}
                    passwordValidation={passwordValidation}
                    confirmPasswordRef={confirmPasswordRef}
                    handleConfirmPasswordChange={handleConfirmPasswordChange}
                    confirmValidation={confirmValidation}
                    useMessageSigning={useMessageSigning}
                    setUseMessageSigning={setUseMessageSigning}
                    inviteOnly={inviteOnly}
                    setInviteOnly={setInviteOnly}
                    handleInitialize={handleInitialize}
                    error={error}
                    account={account}
                />
            ) : !isLoggedIn ? (
                <LoginForm />
            ) : (
                <MessengerInterface 
                    conversations={conversations}
                    selectedConversationIndex={selectedConversationIndex}
                    handleConversationClick={handleConversationClick}
                    setIsNewChat={setIsNewChat}
                    isNewChat={isNewChat}
                    handleStartNewConversation={handleStartNewConversation}
                    web3={web3}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    handleSendMessage={handleSendMessage}
                    handleScroll={handleScroll}
                    fetchProfileData={fetchProfileData}
                    account={account}
                    contract={contract}
                    />
                  )}
              </>
          )}
      </Container>
  );
};



export default MessengerComponent;