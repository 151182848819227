// dateUtils.js

const MINUTES_IN_HOUR = 60;
const MINUTES_IN_DAY = 1440;
const MINUTES_IN_MONTH = 43200; // Approximate, using 30 days
const MINUTES_IN_YEAR = 525600; // Approximate, using 365 days

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${hours}:${minutes}, ${month}/${day}/${year}`;
};

export const getRelativeTimeString = (timestamp) => {
  const now = new Date();
  const date = new Date(timestamp);
  const diffInMinutes = Math.round((now - date) / 60000);

  if (diffInMinutes < 1) {
    return 'just now';
  } else if (diffInMinutes < MINUTES_IN_HOUR) {
    return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
  } else if (diffInMinutes < MINUTES_IN_DAY) {
    const hours = Math.floor(diffInMinutes / MINUTES_IN_HOUR);
    return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
  } else if (diffInMinutes < MINUTES_IN_MONTH) {
    const days = Math.floor(diffInMinutes / MINUTES_IN_DAY);
    return `${days} day${days !== 1 ? 's' : ''} ago`;
  } else if (diffInMinutes < MINUTES_IN_YEAR) {
    const months = Math.floor(diffInMinutes / MINUTES_IN_MONTH);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {
    const years = Math.floor(diffInMinutes / MINUTES_IN_YEAR);
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};