export const messageFacetABI = [
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_comment",
        "type": "bytes"
      },
      {
        "internalType": "uint256",
        "name": "_messageId",
        "type": "uint256"
      }
    ],
    "name": "commentMessage",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_messageID",
        "type": "uint256"
      },
      {
        "internalType": "bool",
        "name": "toggle",
        "type": "bool"
      }
    ],
    "name": "toggleUpvote",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_messageId",
        "type": "uint256"
      }
    ],
    "name": "deleteMessage",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_messageId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_commentId",
        "type": "uint256"
      }
    ],
    "name": "deleteComment",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "bytes",
        "name": "_message",
        "type": "bytes"
      }
    ],
    "name": "displayMessage",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalMessages",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_fromIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_toIndex",
        "type": "uint256"
      }
    ],
    "name": "getMessages",
    "outputs": [
      {
        "internalType": "bytes[]",
        "name": "newMessages",
        "type": "bytes[]"
      },
      {
        "internalType": "address[]",
        "name": "messageOwners",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "messageTimes",
        "type": "uint256[]"
      },
      {
        "internalType": "uint256[]",
        "name": "messageCommentLength",
        "type": "uint256[]"
      },
      {
        "internalType": "uint256[]",
        "name": "upvotes",
        "type": "uint256[]"
      },
      {
        "internalType": "bool[]",
        "name": "upvoted",
        "type": "bool[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getUpvotedMessages",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "_messageId",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_fromIndex",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_toIndex",
        "type": "uint256"
      }
    ],
    "name": "getCommentFromMessage",
    "outputs": [
      {
        "internalType": "bytes[]",
        "name": "newMessages",
        "type": "bytes[]"
      },
      {
        "internalType": "address[]",
        "name": "messageOwners",
        "type": "address[]"
      },
      {
        "internalType": "uint256[]",
        "name": "messageTimes",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_user",
        "type": "address"
      }
    ],
    "name": "getUserMessages",
    "outputs": [
      {
        "internalType": "bytes[]",
        "name": "",
        "type": "bytes[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "getMessageOwner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "getMessageTime",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "index",
        "type": "uint256"
      }
    ],
    "name": "getSpecificMessage",
    "outputs": [
      {
        "internalType": "bytes",
        "name": "",
        "type": "bytes"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "checkMessageConsistency",
    "outputs": [
      {
        "internalType": "bool",
        "name": "isConsistent",
        "type": "bool"
      },
      {
        "internalType": "uint256",
        "name": "messages",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "owners",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "timestamps",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "getAllTimes",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];